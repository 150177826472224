import './Filters.scss'

import { cls } from '@react/utils/classname'
import { useContext } from 'react'

import { FilterbarContext } from '../FilterbarContext'
import { FilterField, FiltersProps, SelectedFilter } from './FiltersTypes'
import { FilterSwitcher } from './FilterSwitcher'

const getRangeSliderSelectedSubFilters = (selectedFilters: SelectedFilter[], filterField: FilterField) => {
  const selectedRangeFiltersSubFiltersValues = selectedFilters
    ?.filter((selectedFilter: SelectedFilter) => selectedFilter.key === `${filterField.key}Type`)
    ?.map(selectedRanges => selectedRanges.value)
    ?.flat()

  if (selectedRangeFiltersSubFiltersValues.length) {
    return { key: `${filterField.key}Type`, value: selectedRangeFiltersSubFiltersValues }
  }
}

const getRangeSliderSelectedFilters = (selectedFilters: SelectedFilter[], filterField: FilterField) => {
  const selectedRangeFiltersValues = selectedFilters
    ?.filter((selectedFilter: SelectedFilter) => selectedFilter.key === `${filterField.key}.from` || selectedFilter.key === `${filterField.key}.to`)
    ?.map(selectedRanges => selectedRanges.value)
    ?.flat()

  const subFilter = getRangeSliderSelectedSubFilters(selectedFilters, filterField)

  if (selectedRangeFiltersValues?.length || subFilter?.value.length) {
    return { key: filterField.key, value: selectedRangeFiltersValues, subFilter }
  }
}

const getSelectedFilter = (selectedFilters: SelectedFilter[], filterField: FilterField) =>
  filterField.type === 'rangeSlider'
    ? getRangeSliderSelectedFilters(selectedFilters, filterField)
    : selectedFilters?.find((selectedFilter: SelectedFilter) => selectedFilter.key === (filterField.quickFilterType || filterField.key))

export function Filters({ type, isLoading }: FiltersProps) {
  const { activeDropdowns, selectedFilters, filterFieldsState, secondaryFilterFieldsState } = useContext(FilterbarContext)
  const filterFields = type === 'primary' ? filterFieldsState : secondaryFilterFieldsState

  return (
    <div
      data-dm="filterbar.select"
      className={cls({
        'cmp-filterbar__filters': true,
        'cmp-filterbar__filters--is-open': activeDropdowns?.length > 0,
      })}
    >
      {filterFields?.map(filterField => {
        return (
          <FilterSwitcher
            key={filterField.key}
            filterField={filterField}
            isLoading={isLoading}
            selectedFilter={getSelectedFilter(selectedFilters, filterField)}
          />
        )
      })}
    </div>
  )
}
