import './Filterbar.scss'

import { ActiveFilters } from '@react/Filterbar/ActiveFilters'
import LoadingSpinner from '@react/LoadingSpinner'
import { cls } from '@react/utils/classname'
import { replaceLabelPlaceholders } from '@site/js/utils/utils'
import { useEffect } from 'react'

import { AdditionalFilters } from './AdditionalFilters'
import { FilterbarContext } from './FilterbarContext'
import { FilterbarFooter } from './FilterbarFooter'
import { FilterbarHeader } from './FilterbarHeader'
import { Filters } from './Filters'
import { AnalyticsType, FilterField, SelectedFilter, TranslationsType } from './Filters/FiltersTypes'
import { getActiveFilters } from './helpers'
import { useFilterbar } from './hooks'

type FilterbarPropsType = {
  translations: TranslationsType
  analytics: AnalyticsType
  filterFields: FilterField[]
  selectedFiltersProp: SelectedFilter[]
  secondaryFilterFields: FilterField[]
  title: string
  totalNumber: number
  isLoading: boolean
  componentName?: string
  dataDm?: string
  onSelectedFiltersChange: (selectedFilters: SelectedFilter[]) => void
  onClearFilters?: () => void
}

export function Filterbar({
  analytics,
  filterFields,
  selectedFiltersProp,
  secondaryFilterFields,
  totalNumber,
  translations,
  isLoading,
  componentName,
  dataDm,
  onSelectedFiltersChange,
  onClearFilters,
}: FilterbarPropsType) {
  const {
    activeDropdowns,
    activeFiltersState,
    analyticsState,
    filterFieldsState,
    isFilterbarOpened,
    isToggled,
    headerTitle,
    selectedFilters,
    secondaryFilterFieldsState,
    totalNumberState,
    translationsState,
    selectButtonTitle,
    selectedFilterState,
    handleClearAllFilters,
    handleClearFilter,
    handleFilterChange,
    setActiveDropdowns,
    setActiveFiltersState,
    setAnalyticsState,
    setFilterFieldsState,
    setHeaderTitle,
    setIsFilterbarOpened,
    setIsToggled,
    setSecondaryFilterFieldsState,
    setSelectButtonTitle,
    setSelectedFilters,
    setSelectedFilterState,
    setTotalNumberState,
    setTranslationsState,
  } = useFilterbar({ analytics, filterFields, secondaryFilterFields, selectedFiltersProp, totalNumber, translations, onClearFilters })

  useEffect(() => {
    setTranslationsState(translations)
  }, [translations])

  useEffect(() => {
    setAnalyticsState(analytics)
  }, [analytics])

  useEffect(() => {
    setSelectedFilters(selectedFiltersProp)
  }, [selectedFiltersProp])

  useEffect(() => {
    setFilterFieldsState(filterFields)
  }, [filterFields])

  useEffect(() => {
    setSecondaryFilterFieldsState(secondaryFilterFields)
  }, [secondaryFilterFields])

  useEffect(() => {
    setTotalNumberState(totalNumber)
  }, [totalNumber])

  useEffect(() => {
    onSelectedFiltersChange(selectedFilters)
  }, [selectedFilters])

  useEffect(() => {
    setActiveFiltersState(getActiveFilters(filterFieldsState, secondaryFilterFieldsState, selectedFilters))
  }, [filterFieldsState, secondaryFilterFieldsState])

  useEffect(() => {
    setSelectedFilterState(
      selectedFilters?.filter(selectedFilter => activeFiltersState.find(activeFilter => activeFilter.key === selectedFilter.key)),
    )
  }, [activeFiltersState])

  useEffect(() => {
    if (!isFilterbarOpened) {
      return
    }
    if (activeDropdowns?.length) {
      setSelectButtonTitle(translationsState?.filterbarSelect || 'Select')
    } else {
      setSelectButtonTitle(
        replaceLabelPlaceholders(
          totalNumber == 1 ? translationsState?.screenDisplayResult || 'View {0} item' : translationsState?.screenDisplayResults || 'View {0} items',
          totalNumberState,
        ),
      )
    }
  }, [isFilterbarOpened, activeDropdowns?.length, totalNumberState])

  useEffect(() => {
    isFilterbarOpened ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll')
  }, [isFilterbarOpened])

  const openFilterbarHandler = () => setIsFilterbarOpened(!isFilterbarOpened)
  return (
    <FilterbarContext.Provider
      value={{
        analyticsState,
        setAnalyticsState,
        dataDm,
        componentName,
        translationsState,
        setTranslationsState,
        selectedFilterState,
        setSelectedFilterState,
        isFilterbarOpened,
        setIsFilterbarOpened,
        selectButtonTitle,
        setSelectButtonTitle,
        headerTitle,
        setHeaderTitle,
        activeDropdowns,
        setActiveDropdowns,
        isToggled,
        setIsToggled,
        selectedFilters,
        setSelectedFilters,
        filterFieldsState,
        setFilterFieldsState,
        secondaryFilterFieldsState,
        setSecondaryFilterFieldsState,
        totalNumberState,
        setTotalNumberState,
        activeFiltersState,
        setActiveFiltersState,
        onFilterChange: handleFilterChange,
        handleClearFilter,
        handleClearAllFilters,
      }}
    >
      {filterFieldsState?.length > 0 && (
        <div className="cmp-filterbar">
          {isLoading && <LoadingSpinner />}
          <button data-dm="filterbar.button" onClick={openFilterbarHandler} className="cmp-filterbar__button--is-open-filterbar">
            {translationsState?.filterbarTitle || 'Filter'}
          </button>
          <div className={cls({ 'cmp-filterbar__wrapper': true, 'cmp-filterbar__wrapper--is-open': isFilterbarOpened })}>
            <FilterbarHeader />
            <div className="cmp-filterbar__body">
              <Filters isLoading={isLoading} type="primary" />
              {secondaryFilterFieldsState?.length > 0 && (
                <div className="cmp-filterbar__secondary-filters">
                  <AdditionalFilters />

                  {isToggled && <Filters isLoading={isLoading} type="secondary" />}
                </div>
              )}
              {/* Show only on demand events */}
              <ActiveFilters />
            </div>
          </div>
          <FilterbarFooter />
        </div>
      )}
    </FilterbarContext.Provider>
  )
}
