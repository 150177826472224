import './Abbreviation.scss'

import { useRef, useState } from 'react'

import getPositionClass from './getPositionClass'
import AbbreviationTemplate from './subcomponents/AbbreviationTemplate'

interface AbbreviationProps {
  title: string
  value: string
  abbrString?: string
  abbrStringInline?: string
  isArray?: string
  toolTipType?: string
}

const initialAbbreviationClass = 'cmp-abbreviation-tooltip--is-below-center'

export default function Abbreviation({ title, value, abbrString = '', isArray = 'false', toolTipType = 'abbreviation' }: AbbreviationProps) {
  const [isHovered, setIsHovered] = useState(false)
  const [abbreviationClass, setAbbreviationClass] = useState(initialAbbreviationClass)

  const tooltipRef = useRef(null)
  const position = tooltipRef?.current?.getBoundingClientRect()

  const showTooltip = (element: HTMLElement | EventTarget) => {
    if (!isHovered) {
      const elementPosition = (element as HTMLElement)?.getBoundingClientRect()
      setAbbreviationClass(getPositionClass(elementPosition))
      setIsHovered(!isHovered)
    }
  }

  const hideTooltip = () => {
    setIsHovered(false)
    setAbbreviationClass(initialAbbreviationClass)
  }

  return toolTipType === 'tooltip' ? (
    <div ref={tooltipRef} onMouseEnter={e => showTooltip(e.target)} onMouseLeave={hideTooltip}>
      <AbbreviationTemplate
        abbreviationClass={abbreviationClass}
        abbrString={abbrString}
        isHovered={isHovered}
        title={title}
        value={value}
        isTooltip
        isArray={isArray === 'true'}
        position={position}
        hideTooltip={hideTooltip}
        showTooltip={showTooltip}
      />
    </div>
  ) : (
    <AbbreviationTemplate
      abbreviationClass={abbreviationClass}
      abbrString={abbrString}
      isHovered={isHovered}
      title={title}
      value={value}
      hideTooltip={hideTooltip}
      showTooltip={showTooltip}
    />
  )
}
