import './ListTypeItem.scss'

import { ReactNode } from 'react'

import { ItemType } from '../FilterableListTypes'
import { Card } from './Card'

type ListTypeItemProps = {
  buttonLabel?: string
  children?: ReactNode
  customTitle?: ReactNode
  item: ItemType
  showButton?: boolean
  showDescription?: boolean
  showMetaTag?: boolean
  showMetaDate?: boolean
  showMetaTimeToRead?: boolean
  showTag?: boolean
}

export const ListTypeItem = ({
  buttonLabel = '',
  children,
  customTitle,
  item,
  showButton = false,
  showDescription = false,
  showMetaTag = true,
  showMetaDate = true,
  showMetaTimeToRead = true,
  showTag = false,
}: ListTypeItemProps) => {
  if (showMetaDate) {
    item.dateString = item.dateString || item.date?.split(' ')?.[0]
  }

  return (
    <Card
      item={item}
      customTitle={customTitle}
      classes="cmp-filterable-list__card--list-type"
      showDescription={showDescription}
      showMetaTag={showMetaTag}
      showMetaDate={showMetaDate}
      showMetaTimeToRead={showMetaTimeToRead}
      showTag={showTag}
      showButton={showButton}
      buttonLabel={buttonLabel}
    >
      {children}
    </Card>
  )
}
