/* eslint-disable sonarjs/cognitive-complexity */
import { DropdownItems, FilterField } from '@react/Filterbar/Filters/FiltersTypes'

import { LAST_ACTIVE_FILTER } from './constants'

// Get filters from array1 not in array2
function getFilterDifference(array1: FilterField[] & DropdownItems[], array2: FilterField[] & DropdownItems[]): FilterField[] & DropdownItems[] {
  return array1?.filter(object1 => !array2?.some(object2 => object1.key === object2.key)) || []
}

function sortArrayOfObjectsByStringKey(arr: FilterField[] & DropdownItems[], key: string): FilterField[] & DropdownItems[] {
  return arr.sort((a, b) => {
    return a[key].toLowerCase() - b[key].toLowerCase()
  })
}

const getFilters = ({
  currentData = [],
  newData = [],
  filterType,
  filterKey,
  level,
}: {
  currentData: FilterField[] & DropdownItems[]
  newData: FilterField[] & DropdownItems[]
  filterType?: string
  filterKey?: string
  level: number
}): FilterField[] & DropdownItems[] => {
  // Load latest option user selected, so we do not disable this filter
  const sessionStorageValue = sessionStorage.getItem(LAST_ACTIVE_FILTER)
  const lastActiveFilter = JSON.parse(sessionStorageValue && sessionStorageValue !== 'undefined' ? sessionStorageValue : '""')

  // Handle new filters from newData only. Eg. newData.length > currentData.length
  const diff = getFilterDifference(newData, currentData)

  const filters = currentData.reduce((acc, filter) => {
    const filterKeyToIgnore = filterKey || filter.key
    const found = newData.find(function (foundFilter) {
      return foundFilter.key === filter.key
    })

    if (found) {
      return [
        ...acc,
        {
          ...found,
          disabled: found.items?.length <= 0,
          ...(found.items?.length > 0
            ? { items: getFilters({ currentData: filter.items, newData: found.items, filterKey: filterKeyToIgnore, filterType, level: 1 }) }
            : {}),
          ...((found as DropdownItems).children?.length > 0
            ? {
                children: getFilters({
                  currentData: (filter as DropdownItems).children,
                  newData: (found as DropdownItems).children,
                  filterKey: filterKeyToIgnore,
                  filterType,
                  level: 2,
                }),
              }
            : {}),
          ...((found as FilterField).subFilters?.length > 0
            ? {
                subFilters: getFilters({
                  currentData: (filter as FilterField).subFilters,
                  newData: (found as FilterField).subFilters,
                  filterKey: filterKeyToIgnore,
                  filterType,
                  level: 2,
                }),
              }
            : {}),
        },
      ]
    }

    // Not found, copy previous data, disable if not last selected filter OR keep previous disabled status
    return [
      ...acc,
      {
        ...filter,
        disabled: filterKeyToIgnore !== lastActiveFilter || filter.disabled,
      },
    ]
  }, [])

  // At parent level we want sorted by label and at items/children level we want sorted by value
  return sortArrayOfObjectsByStringKey([...filters, ...diff], level === 0 ? 'label' : 'value')
}

export const mergeAndUpdate = ({
  currentData,
  newData,
  filterType,
  filterKey,
}: {
  currentData: FilterField[]
  newData: FilterField[]
  filterType?: string
  filterKey?: string
}): FilterField[] => {
  // Nothing back, we do not display
  if (!newData) {
    return []
  }

  // Initial state (currentData) is empty, get inital data (newData) on page load
  if (!currentData) {
    return newData.map(e => ({ ...e, disabled: e.items?.length <= 0 }))
  }

  if (filterType === 'quickfilter') {
    return currentData
  }

  return getFilters({ currentData, newData, filterType, filterKey, level: 0 })
}

export const setFiltersFromQueryParams = ({ queryParams = [], selectedFilters = [], prefix = '', setOffsetState, setCalculatedOffsetState }) => {
  queryParams?.forEach(param => {
    const filterParam = param.split('=')
    if (filterParam[0].includes(prefix)) {
      const key = filterParam[0].substring(prefix.length)
      const value = filterParam[1].split(',')
      selectedFilters.push({ key, value })

      switch (key) {
        case 'offset':
          setOffsetState([value])
          break
        case 'calculatedOffset':
          setCalculatedOffsetState([value])
          break
        default:
          break
      }
    }
  })
}

export const arrayFromSearchQueryParams = (): string[] => {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.size ? decodeURIComponent(queryParams.toString()).replace(/\+/g, ' ').split('&') : []
}

export const getSearchQueryParams = (prefix: string, current = true) =>
  uniqueArrayElements(
    arrayFromSearchQueryParams()?.filter(queryParam => {
      const key = queryParam.split('=')
      return current ? key[0].includes(prefix) : !key[0].includes(prefix)
    }),
  )

export const multipleSelectionHandler = (prefix: string, current = true) => {
  return getSearchQueryParams(prefix, current)?.map(queryParam => {
    const multipleSelected = []
    const params = queryParam.split('=')
    const values = params[1].split(',')
    values.forEach(value => multipleSelected.push(`${params[0]}=${value}`))
    return multipleSelected.join('&')
  })
}

export const uniqueArrayElements = (array: string[]) => [...new Set(array)]

export const getUrl = ({
  currentSearchQueryParams,
  prefix,
  offset,
  calculatedOffset,
  urlFromProps,
}: {
  currentSearchQueryParams: string
  prefix: string
  offset: string[]
  calculatedOffset: string[]
  urlFromProps: string
}): string => {
  const offsetsQueryString =
    currentSearchQueryParams?.search('offset') > -1 || currentSearchQueryParams?.search('calculatedOffset') > -1
      ? ''
      : `${prefix}offset=${offset[0]}&${prefix}calculatedOffset=${calculatedOffset[0]}`

  if (currentSearchQueryParams) {
    return `${urlFromProps}?${currentSearchQueryParams}${offsetsQueryString ? '&' + offsetsQueryString : ''}`
  } else {
    return `${urlFromProps}${offsetsQueryString ? '?' + offsetsQueryString : ''}`
  }
}
