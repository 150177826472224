export const loadingSpinnerSvg = `<svg class="loading-spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" fill="none">
  <g class="loading-spinner__tower" clip-path="url(#clip0_3478_56377)">
    <rect width="60" height="60" rx="6" fill="#2D2D2D"/>
    <path d="M30.8449 28.9185L32.9946 48.2838V53.6648H26.541V48.2838L28.6907 28.9185" fill="white"/>
    <path class="loading-spinner__blades" d="M29.7681 29.4591C31.2519 29.4591 32.4586 28.2569 32.4586 26.7686C32.4586 25.2804 31.2563 24.0781 29.7681 24.0781C28.2798 24.0781 27.082 25.2804 27.082 26.7686C27.082 28.2569 28.2843 29.4591 29.7681 29.4591Z" fill="white"/>
    <path class="loading-spinner__blades" d="M33.9262 31.8188L32.0625 32.8959C32.2279 34.0445 32.4871 35.135 32.7418 36.23C33.6312 38.201 39.0524 45.0032 39.9821 44.4669C40.9117 43.9306 38.0916 36.2434 36.4692 34.0758C35.6513 33.3071 34.8335 32.5339 33.9262 31.8188Z" fill="white"/>
    <path class="loading-spinner__blades" d="M23.3158 27.8412V25.6915C22.2387 25.2624 21.1661 24.9362 20.089 24.6144C17.9393 24.3999 9.33594 25.6915 9.33594 26.7641C9.33594 27.8367 17.403 29.2401 20.089 28.9138C21.1661 28.5921 22.2387 28.2703 23.3158 27.8367V27.8412Z" fill="white"/>
    <path class="loading-spinner__blades" d="M32.0625 20.6409L33.9262 21.718C34.8379 21.0029 35.6558 20.2297 36.4692 19.461C37.7295 17.7046 40.9161 9.6107 39.9821 9.06992C39.048 8.52914 33.8055 14.8174 32.7418 17.3068C32.4826 18.3973 32.2234 19.4923 32.0625 20.6409Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_3478_56377">
      <rect width="60" height="60" fill="white"/>
    </clipPath>
</defs>
</svg>`
