export default function getPositionClass(element) {
  if (!element) {
    return ''
  }

  const isAbove = window.innerHeight - element.y < 100
  const isLeft = element.left < 0
  const isRight = element.right >= window.innerWidth

  if (isAbove) {
    return `cmp-abbreviation-tooltip--is-above-${isLeft ? 'left' : isRight ? 'right' : 'center'}`
  }

  return `cmp-abbreviation-tooltip--is-below-${isLeft ? 'left' : isRight ? 'right' : 'center'}`
}
