import { TableRowsType } from '@react/FilterableList/UI/Table'
import ImageWrapper from '@react/ImageWrapper'

export const TableCell = ({ data }: { data: TableRowsType }) => {
  return (
    <>
      {data.image && (
        <ImageWrapper
          modelJson={JSON.stringify(data.image)}
          classes="cmp-filterable-list__card-image cmp-responsive-image cmp-responsive-image--lazy"
          imageSrc={data.image.placeholder}
        />
      )}
      {!data.image && (data.value || '-')}
    </>
  )
}
