/* eslint-disable fp/no-class */

// first we use encodeURIComponent to get percent-encoded UTF-8,
// then we convert the percent encodings into raw bytes which
// can be fed into btoa.
function b64EncodeUnicode(str) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1)
    }),
  )
}

class AssetProtector {
  constructor() {
    this.secureUrl = document.body.dataset.secureUrlPattern
    this.enabled = !!this.secureUrl
  }

  protect(url) {
    if (!this.enabled) {
      return url
    }
    return this.secureUrl.replace(/\$\{.*\}/, b64EncodeUnicode(url))
  }
}

export const assetProtector = new AssetProtector()
/* eslint-enable fp/no-class */
