import './EventCarouselHeader.scss'

type EventCarouselHeaderProps = {
  allEvents?: string
  anchorLabel: string
  eventsPageLink?: string
  title: string
  titleId: string
}

export const EventCarouselHeader = ({ allEvents, anchorLabel, eventsPageLink, title, titleId }: EventCarouselHeaderProps) => {
  return (
    <header className="cmp-carousel__header u-container-width">
      {title && (
        <h2 id={titleId} data-anchor-label={anchorLabel || ''} className="cmp-title__text cmp-carousel__header--title">
          {title}
        </h2>
      )}

      {eventsPageLink && (
        <a className="cmp-button cmp-carousel__header--allevents-link" href={eventsPageLink} data-dm="events.link">
          <span className="cmp-button__text">{allEvents}</span>
          <span className="cmp-button__icon cmp-button__icon--keyboard_arrow_right icon--sm"></span>
        </a>
      )}
      {!eventsPageLink && <span className="cmp-carousel__header--allevents-link"></span>}
    </header>
  )
}
