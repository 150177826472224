import { FilterbarContext } from '@react/Filterbar/FilterbarContext'
import { KeyboardEvent, MouseEvent, useContext, useState } from 'react'

import { DropdownItems, SelectedCheckbox, SelectedFilter } from '../../FiltersTypes'

type useCheckboxProps = {
  item: DropdownItems
  filterKey?: string
  onSelectItem?: (selectedCheckbox: SelectedCheckbox[]) => void
}

export const useCheckbox = ({ item, filterKey, onSelectItem }: useCheckboxProps) => {
  const [expandedCheckboxContainer, setExpandedCheckboxContainer] = useState(false)
  const { selectedFilters, setSelectedFilterState, onFilterChange } = useContext(FilterbarContext)

  const hasChidlren = item?.children?.length > 0
  const isParent = item?.parent === null

  const toggleCheckbox = (key = '') => {
    let selectedCheckbox = null

    selectedCheckbox = hasChidlren
      ? item.children
          // Exclude the one disabled for active filters
          .filter((child: DropdownItems) => !child.disabled)
          .map((child: DropdownItems) => {
            return { ...child, selected: child.disabled ? item.selected : !item.selected }
          })
      : [{ ...item, key: key || item.key, selected: !item.selected, parent: key ? item.parent : null }]

    if (onSelectItem) {
      onSelectItem(selectedCheckbox)
    } else {
      handleStandAloneCheckboxState(selectedFilters, selectedCheckbox[0])
    }
  }

  const handleStandAloneCheckboxState = (selectedFilters: SelectedFilter[], selectedCheckbox: SelectedCheckbox) => {
    const existingSelectedFilterCheckbox = selectedFilters.find(selectedFilter => selectedFilter.key === filterKey)
    let selectedValues: string[] = []

    if (existingSelectedFilterCheckbox) {
      selectedValues = selectedCheckbox.selected
        ? [...existingSelectedFilterCheckbox.value, selectedCheckbox.key]
        : existingSelectedFilterCheckbox.value.filter(filterValue => filterValue !== selectedCheckbox.key)
    } else {
      selectedValues = selectedCheckbox.selected ? [selectedCheckbox.key] : []
    }

    setSelectedFilterState({ key: filterKey, value: selectedValues })
    onFilterChange({ key: filterKey, value: selectedValues })
  }

  const handleOnClick = (e: MouseEvent, option = { key: '', disabled: false }) => {
    e.stopPropagation()

    if (option.disabled) {
      return
    }

    toggleCheckbox(option.key)

    if (hasChidlren) {
      setExpandedCheckboxContainer(true)
    }
  }

  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>, disabled = false) => {
    if (event.key === ' ') {
      event.preventDefault()
    }

    if (disabled) {
      return
    }
  }

  const handleOnKeyUp = (event: KeyboardEvent<HTMLDivElement>, option = { key: '', disabled: false }) => {
    if (option.disabled) {
      return
    }

    if (event.key === ' ') {
      event.stopPropagation()
      toggleCheckbox(option.key)
    }
  }

  const handleOnClickArrow = (event: MouseEvent) => {
    event.stopPropagation()
    setExpandedCheckboxContainer(!expandedCheckboxContainer)
  }

  return {
    expandedCheckboxContainer,
    hasChidlren,
    isParent,
    toggleCheckbox,
    handleOnClick,
    handleOnKeyDown,
    handleOnKeyUp,
    handleOnClickArrow,
    setExpandedCheckboxContainer,
  }
}
