import { assetProtector } from '@site/js/utils/asset-protector'
import { breakpointValues } from '@site/js/utils/breakpoint'

import { failElement, imagePathFromPattern, optimizeDimensions, REQUIRED_FORMAT, REQUIRED_SCALES } from './common.js'

const mediaSourceMapping = {
  DESKTOP: `(min-width: ${breakpointValues.upMd}px)`,
  TABLET: `(min-width: ${breakpointValues.upSm}px) and (max-width: ${breakpointValues.downMd}px)`,
  MOBILE: `(max-width: ${breakpointValues.downSm}px)`,
}

export const RESPONSIVE_IMAGE_CLASS = 'cmp-responsive-image--lazy'
export const RESPONSIVE_BG_IMAGE_CLASS = 'cmp-responsive-bg-image--lazy'
export const RESPONSIVE_IMAGE_SELECTOR = `.${RESPONSIVE_IMAGE_CLASS}:not(.in-fault):not(.replaced)`
export const RESPONSIVE_BG_IMAGE_SELECTOR = `.${RESPONSIVE_BG_IMAGE_CLASS}:not(.in-fault):not(.replaced)`

export const ResponsiveBackgroundImage = element => {
  const newSrcSet = format => {
    return REQUIRED_SCALES.map(scale => {
      const path = imagePathFromPattern(element.config.pattern, {
        width: element.newDimensions[scale].width,
        height: element.newDimensions[scale].height,
        format,
      })

      return `url(${assetProtector.protect(path)}) ${scale}`
    }).join(', ')
  }

  const newSource = (path, format) => {
    return `background-image: -webkit-image-set(${newSrcSet(format.format)}); background-image: image-set(${newSrcSet(format.format)});`
  }

  const generateSources = () => {
    element.style.cssText = `${element.style.cssText} ${newSource(element.config.pattern, REQUIRED_FORMAT())}`
  }

  element.replaceImage = () => {
    if (!element.config || !element.config.pattern) {
      failElement(element, new Error('Missing config or pattern'))
      return
    }

    const { width, height } = element.getBoundingClientRect()

    element.newDimensions = {}

    REQUIRED_SCALES.forEach(scale => {
      const scaleFactor = parseFloat(scale)
      const configuration = {
        initialWidth: width,
        initialHeight: height,
        scaleFactor,
      }
      element.newDimensions[scale] = optimizeDimensions(element, configuration)
    })

    generateSources()

    element.classList.add('replaced')
  }

  element.init = () => {
    try {
      element.config = JSON.parse(element.dataset.imageSrc || element.dataset.bgImageSrc)
    } catch (e) {
      failElement(element, e)
    }
    if (!element.config) {
      failElement(element, new Error('Config not found'))
    }
  }
  return element
}

export const ResponsiveImage = element => {
  const newSrcSet = (format, aspectRatio) => {
    return REQUIRED_SCALES.map(scale => {
      const path = imagePathFromPattern(element.config.pattern, {
        width: element.newDimensions[scale].width,
        height: element.newDimensions[scale].height,
        aspectRatio,
        format,
      })
      return `${assetProtector.protect(path)} ${scale}`
    }).join(', ')
  }

  const newSource = (format, viewport) => {
    const sourceElement = document.createElement('source')
    sourceElement.type = format.type
    sourceElement.srcset = viewport ? newSrcSet(format.format, element.config.aspectRatio[viewport]) : newSrcSet(format.format)

    if (viewport && mediaSourceMapping[viewport]) {
      sourceElement.media = mediaSourceMapping[viewport]
    }

    return sourceElement
  }

  const generateSources = () => {
    element.config.aspectRatio
      ? Object.keys(element.config.aspectRatio).forEach(key => {
          element.pictureElement.insertBefore(newSource(REQUIRED_FORMAT(), key), element.imageElement)
        })
      : element.pictureElement.insertBefore(newSource(REQUIRED_FORMAT()), element.imageElement)
  }

  element.replaceImage = () => {
    if (!element.config || !element.config.pattern) {
      failElement(element, new Error('Missing config or pattern'))
      return
    }

    const { width, height } = element.imageElement.getBoundingClientRect()
    element.newDimensions = {}

    REQUIRED_SCALES.forEach(scale => {
      const scaleFactor = parseFloat(scale)
      const configuration = {
        initialWidth: width,
        initialHeight: height,
        scaleFactor,
      }
      element.newDimensions[scale] = optimizeDimensions(element, configuration)
    })

    if (element.config.generateSources) {
      generateSources()
    }

    element.imageElement.alt = element.config.alt || ''
    const path = imagePathFromPattern(element.config.pattern, {
      width: element.newDimensions['1x'].width,
      height: element.newDimensions['1x'].height,
      format: REQUIRED_FORMAT().format,
    })

    element.imageElement.src = `${assetProtector.protect(path)}`

    element.classList.add('replaced')
  }

  element.init = () => {
    try {
      element.pictureElement = element.querySelector('picture')
      element.imageElement = element.pictureElement.querySelector('img')
      element.config = JSON.parse(element.dataset.imageSrc)
    } catch (e) {
      failElement(element, e)
    }
    if (!element.config) {
      failElement(element, new Error('Config not found'))
    }
  }
  return element
}

export const intersectionHandler = (entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (entry.target.classList.contains('in-fault')) {
        entry.target.classList.remove('in-fault')
        entry.target.init()
      }
      if (!entry.target.classList.contains('in-fault') && !entry.target.classList.contains('replaced')) {
        entry.target.replaceImage()
      }
      observer.unobserve(entry.target)
    }
  })
}

export const ResponsiveImageObserver = images => {
  if (!images || images.length <= 0) {
    return
  }

  const options = {
    rootMargin: '20%',
  }

  if ('IntersectionObserver' in window) {
    const lazyImageObserver = new IntersectionObserver(intersectionHandler, options)

    images.forEach(image => lazyImageObserver.observe(image))
  }
}

export const ResponsiveImageComponent = imageElement => {
  if (imageElement.classList.contains(RESPONSIVE_BG_IMAGE_CLASS)) {
    imageElement = ResponsiveBackgroundImage(imageElement)
    imageElement.init()
    ResponsiveImageObserver(ResponsiveBackgroundImage([imageElement]))
  }

  if (imageElement.classList.contains(RESPONSIVE_IMAGE_CLASS)) {
    imageElement = ResponsiveImage(imageElement)
    imageElement.init()
    ResponsiveImageObserver(ResponsiveImage([imageElement]))
  }

  return imageElement
}
