import { ItemType, TranslationsType } from '../FilterableListTypes'

export const getProductsTableHeaders = (translationsState: TranslationsType) => {
  return { items: [{ value: translationsState.typeColumn }, { value: translationsState.descriptionColumn }] }
}

export const getProductsTableRows = (items: ItemType[], columns: string[], itemLink: string) => {
  return items?.map(item =>
    columns?.map(column => {
      return {
        value: item[column],
        link: itemLink === column ? item.url : null,
        url: item.url,
      }
    }),
  )
}
