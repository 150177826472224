type IconProps = {
  iconName: string
}

export default function Icon({ iconName }: IconProps) {
  return (
    <div className="a-icon">
      <span className={`icon icon-${iconName}`}></span>
    </div>
  )
}
