import { formatDate, TemporalType } from '@site/js/utils/formatDate'

import { ItemType } from '../FilterableListTypes'

export const modifyVideoItem = ({
  item,
  viewLabel,
  viewsLabel,
  temporal,
}: {
  item: ItemType
  viewLabel: string
  viewsLabel: string
  temporal: TemporalType
}) => {
  if (item.viewCounter > 0) {
    item.tagValue = `${item.viewCounter} ${item.viewCounter === 1 ? viewLabel : viewsLabel}`
  }
  item.dateString = formatDate(item.publicationDate, temporal)
  return item
}
