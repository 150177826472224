import { register, registerComponent } from '@site/js/utils/react-register'
import { FC } from 'react'

import Abbreviation from './Abbreviation'
import AgendaSlider from './AgendaSlider'
import AnchorNavigation from './AnchorNavigation'
import Icon from './Atoms/Icon'
import { Comments } from './Comments'
import ContactHub from './ContactHub'
import { Countdown } from './Countdown'
import GalleryLightbox from './GalleryLightbox'
import ImageWrapper from './ImageWrapper'
import Infobox from './Infobox'
import { TagList } from './TagList'
import TeamsNotificationsInPage from './TeamsNotificationsInPage'
import TheWall from './TheWall'

const body = document.querySelector('body')
const MutationObserver = window.MutationObserver

async function enableMocking() {
  if (!window.enableMSWOnDev || process.env.WEBPACK_SERVE !== 'true') {
    return
  }

  const { worker } = await require('@mocks/browser')
  return worker.start()
}

export async function registerReactComponent(componentName: string, component: FC) {
  enableMocking()
    .then(() => {
      registerComponent(componentName, component)
    })
    .catch(e => console.error(e))
}

export function initReactComponents() {
  enableMocking()
    .then(() => {
      registerComponent('ContactHub', ContactHub)
      registerComponent('TeamsNotificationsInPage', TeamsNotificationsInPage)
      registerComponent('Abbreviation', Abbreviation)
      registerComponent('TheWall', TheWall)
      registerComponent('Icon', Icon)
      registerComponent('Infobox', Infobox)
      registerComponent('AgendaSlider', AgendaSlider)
      registerComponent('GalleryLightbox', GalleryLightbox)
      registerComponent('TagList', TagList)
      registerComponent('Countdown', Countdown)
      registerComponent('AnchorNavigation', AnchorNavigation)
      registerComponent('ImageWrapper', ImageWrapper)
      registerComponent('Comments', Comments)
    })
    .catch(e => console.error(e))
}

export function initReactInAuthorMode() {
  const mutationObserver = new MutationObserver(() => {
    initReactComponents()
  })

  function observeMutations() {
    mutationObserver.observe(body, {
      subtree: true,
      childList: true,
      characterData: true,
    })
  }

  if (window.Granite && window.Granite.author) {
    observeMutations()
  }

  document.dispatchEvent(new window.CustomEvent('ComponentsLoaded'))
}

const abTestingImageWrapperObserver = new MutationObserver(function () {
  try {
    const imageWrapperElement = document?.getElementsByTagName('image-wrapper')
    if (imageWrapperElement?.length > 0) {
      register(imageWrapperElement, (render, rootElement) => {
        const classes = Array.from(rootElement.children[0]?.classList).join(' ') || ''
        const dataImageSrc = JSON.parse((rootElement.children[0] as HTMLElement)?.dataset.imageSrc)
        const modelJson = {
          ...dataImageSrc,
          pattern: `${dataImageSrc?.pattern.split('?')[0]}?${encodeURIComponent('wid=${width}&hei=${height}&fmt=${format}&fit=crop,1')}` || '',
        }
        const imageSrc = rootElement.getElementsByTagName('img')[0]?.src || ''
        const alt = rootElement.getElementsByTagName('img')[0]?.alt || ''

        render(<ImageWrapper modelJson={JSON.stringify(modelJson)} imageSrc={imageSrc} imageAlt={alt} classes={classes} />)
      })
      abTestingImageWrapperObserver.disconnect()
    }
  } catch (error) {
    abTestingImageWrapperObserver.disconnect()
    console.error('abTestingImageWrapperObserver failed.', error)
  }
})
abTestingImageWrapperObserver.observe(document.body, { childList: true, subtree: true })
