import { ListWrapper } from '@react/ListWrapper'
import { registerReactComponent } from '@react/react-component-registry'

const body = document.querySelector('body')
const MutationObserver = window.MutationObserver

function initReactInAuthorMode() {
  const mutationObserver = new MutationObserver(() => {
    registerReactComponent('ListWrapper', ListWrapper)
  })

  function observeMutations() {
    mutationObserver.observe(body, {
      subtree: true,
      childList: true,
      characterData: true,
    })
  }

  if (window.Granite && window.Granite.author) {
    observeMutations()
  }

  document.dispatchEvent(new window.CustomEvent('ComponentsLoaded'))
}

registerReactComponent('ListWrapper', ListWrapper)
initReactInAuthorMode()
