import './FilterTitle.scss'

type FilterTitlePropsType = {
  title: string
  listIcon?: string
}

export function FilterTitle({ title, listIcon }: FilterTitlePropsType) {
  return (
    <>
      {title && (
        <div className="cmp-filter__title">
          <h2 className="cmp-filter__title-heading">
            {listIcon && <span className={`icon-${listIcon} icon--sm`}></span>}
            {title}
          </h2>
        </div>
      )}
    </>
  )
}
