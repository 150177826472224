import Task from 'data.task'

import { clone } from '../utils'
import { initCsrfTokenRefresher, isCsrfProtectionEnabled } from './request-csrf-protection'

/**
 * calls fetch
 * @param {string} url
 * @param {object} options
 * returns a fetch / promise in a Task for lazy evaluation
 */
export const request = (url, options = {}) =>
  new Task((rej, res) =>
    fetch(url, options)
      .then(resp => clone(resp.json()))
      .then(res)
      .catch(rej),
  )

const csrfHeaders = new Headers()
export const getRequest = (url: string): Promise<Response> => {
  return fetch(url, {
    method: 'GET',
    headers: csrfHeaders,
  })
}
export const postRequest = (url: string, body?): Promise<Response> => {
  return fetch(url, {
    method: 'POST',
    body,
    headers: csrfHeaders,
  })
}

export const mockRequest = response => {
  global.fetch = jest.fn(() =>
    Promise.resolve({
      json: () => Promise.resolve(response),
    }),
  ) as jest.Mock
}

export function initRequest() {
  if (isCsrfProtectionEnabled()) {
    initCsrfTokenRefresher(csrfHeaders)
  }
}
