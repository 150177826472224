import './Tags.scss'

import { ActionButton } from '@react/Filterbar/ActionButton'
import { FilterbarContext } from '@react/Filterbar/FilterbarContext'
import { useContext } from 'react'

const getAriaLabel = (label: string) => `Remove filter for: ${label}`

export function Tags() {
  const { translationsState, activeFiltersState, handleClearFilter, handleClearAllFilters } = useContext(FilterbarContext)

  return (
    <div className="cmp-filterbar__tags">
      {activeFiltersState?.map(({ item, key }) => {
        return (
          <ActionButton
            key={item.key}
            buttonLabel={item.value as string}
            buttonType="tag"
            iconName="close"
            iconPlacement="after-label"
            buttonAriaLabel={getAriaLabel(item.value as string)}
            onClickHandler={() =>
              handleClearFilter({
                key,
                value: [item.key],
                type: item.type,
                subFilter: item?.subFilter,
              })
            }
          />
        )
      })}
      {activeFiltersState?.length > 0 && (
        <ActionButton
          buttonLabel={translationsState?.clearAllFiltersLabel || 'Clear all filters'}
          buttonAriaLabel={translationsState?.clearAllFiltersLabel || 'Clear all filters'}
          buttonType="clear-all"
          onClickHandler={handleClearAllFilters}
        />
      )}
    </div>
  )
}
