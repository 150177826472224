import './TagItem.scss'

type TagItemType = {
  tag: string
}

export const TagItem = ({ tag }: TagItemType) => {
  return (
    <span key={tag} className="cmp-filterable-list__card-tags--item">
      {tag}
    </span>
  )
}
