import { useContext } from 'react'

import { FilterableListContext } from './FilterableListContext'
import { AssetSearch } from './ListType/AssetSearch'
import { CustomerSuccessStories } from './ListType/CustomerSuccessStories'
import { Events } from './ListType/Events'
import { FragmentsList } from './ListType/FragmentsList'
import { Jobs } from './ListType/Jobs'
import { News } from './ListType/News'
import { Products } from './ListType/Products'
import { RelatedJobs } from './ListType/RelatedJobs'
import { Videos } from './ListType/Videos'

export const FilterableListSwitcher = () => {
  const { propsState } = useContext(FilterableListContext)

  const renderTemplate = (type: string) => {
    switch (type) {
      case 'news':
        return <News />
      case 'event':
        return <Events />
      case 'customersuccesstory':
        return <CustomerSuccessStories />
      case 'video':
        return <Videos />
      case 'relatedJob':
        return <RelatedJobs />
      case 'job':
        return <Jobs />
      case 'fragmentslist':
        return <FragmentsList />
      case 'product':
        return <Products />
      case 'assetsearch':
        return <AssetSearch />
      default:
        return <p>Non existing template</p>
    }
  }

  return renderTemplate(propsState.type)
}
