import './SortbarDropdown.scss'

import { KeyboardEvent, useState } from 'react'

import { SortbarDropdownPropsType } from '../SortbarTypes'
import { getSortOptions } from './SortOptions'

export default function SortbarDropdown({
  sortable,
  displaySortOrder,
  translations,
  selectedFilters,
  sortByOptions,
  onSortSelect,
}: SortbarDropdownPropsType) {
  const [isOpenDropdown, setIsOpenDropDown] = useState(false)

  const selectedSortOption = selectedFilters?.find(selectedFilter => selectedFilter.key === 'sort')
  const selectedOrderOption = selectedFilters?.find(selectedFilter => selectedFilter.key === 'order')
  const sortOptions = getSortOptions(sortByOptions, translations)
  const selectedOption =
    sortOptions.find(
      sortOption => sortOption.value.order === selectedOrderOption?.value[0] && sortOption.value.sort === selectedSortOption?.value[0],
    ) || sortOptions[0]

  const selectSort = (id: number) => {
    const selectedFilter = sortOptions.find(sortOption => sortOption.id === id)
    const { order, sort } = selectedFilter.value

    if (sort !== selectedSortOption?.value[0] || order !== selectedOrderOption?.value[0]) {
      onSortSelect([
        ...selectedFilters.filter(selectedFilter => selectedFilter.key !== 'sort' && selectedFilter.key !== 'order'),
        { key: 'sort', value: [sort] },
        { key: 'order', value: [order] },
      ])
    }
    setIsOpenDropDown(false)
  }

  const handleOnClickOption = (id: number) => {
    selectSort(id)
  }

  const handleOnKeyDownSortOption = (event: KeyboardEvent, id: number) => {
    if (event.key === ' ') {
      event.preventDefault()
      selectSort(id)
    }
  }

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (event.key === ' ') {
      event.preventDefault()
      handleIsDropdownOpen()
    }
  }

  const handleIsDropdownOpen = () => setIsOpenDropDown(!isOpenDropdown)

  return (
    sortable &&
    !displaySortOrder && (
      <div className="cmp-sortbar__dropdown">
        <span
          className="cmp-sortbar__dropdown-label"
          role="combobox"
          onClick={handleIsDropdownOpen}
          onKeyDown={handleOnKeyDown}
          aria-autocomplete="list"
          aria-expanded={isOpenDropdown}
          aria-label={selectedOption?.label}
          tabIndex={0}
        >
          {selectedOption?.label}
        </span>
        {isOpenDropdown && (
          <div className="cmp-sortbar__dropdown-items" role="listbox" aria-label="sort options">
            {sortOptions.map(sortOption => (
              <div
                className="cmp-sortbar__dropdown-item"
                role="option"
                key={sortOption.id}
                tabIndex={isOpenDropdown ? 0 : -1}
                onClick={() => handleOnClickOption(sortOption.id)}
                onKeyDown={e => handleOnKeyDownSortOption(e, sortOption.id)}
              >
                {sortOption.label}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  )
}
