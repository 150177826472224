import './AssetSearchInfo.scss'

import { ItemType } from '@react/FilterableList/FilterableListTypes'

export const AssetSearchInfo = ({ item }: { item: ItemType }) => {
  const showInfo = item.size || item.type || item.resolution

  return (
    showInfo && (
      <div className="cmp-filterable-list__asset-search-info">
        {item.size && (
          <div className="cmp-filterable-list__asset-search-info--size">
            Size: <span className="cmp-filterable-list__asset-search-info--content">{item.size}</span>
          </div>
        )}
        {item.type && (
          <div className="cmp-filterable-list__asset-search-info--type">
            Type: <span className="cmp-filterable-list__asset-search-info--content">{item.type}</span>
          </div>
        )}
        {item.resolution && (
          <div className="cmp-filterable-list__asset-search-info--resolution">
            Resolution: <span className="cmp-filterable-list__asset-search-info--content">{item.resolution}</span>
          </div>
        )}
      </div>
    )
  )
}
