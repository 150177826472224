import './TeamsNotifications.scss'
import './TeamsNotificationInList.scss'

import { TagList } from '@react/TagList'
import { cls } from '@react/utils/classname'
import { trackEvent } from '@site/js/utils/tracking'
import { useState } from 'react'

export type TeamsNotificationsProps = {
  title?: string
  hintText?: string
  hintUrl?: string
  type?: string
  popupTitle?: string
  channels?: {
    title: string
    channelUrl: string
    webhookUrl: string
  }[]
}

export default function TeamsNotifications({
  title = '',
  hintText = '',
  hintUrl = '',
  type = '',
  popupTitle = '',
  channels = [],
}: TeamsNotificationsProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const acceptedType = type === 'teaser' || type === 'card' || type === 'list' || type === 'testimonial'
  const displayTitle = type === 'page' || (acceptedType && channels.length)
  const displayTitleAsChannel = acceptedType && channels.length === 1

  if (channels.length <= 0) {
    return null
  }

  function handleOnClick() {
    setIsPopupOpen(!isPopupOpen)
  }

  function handleTracking(title) {
    trackEvent({
      event: 'interaction',
      eventSource: 'aem',
      action: 'click',
      componentName: 'ms-teams',
      interactionType: 'channel-subscription',
      title,
    })
  }

  return (
    <div
      className={cls({
        'cmp-teams-notifications': true,
        'active-popup': isPopupOpen,
        [`teams-notifications-in-${type}`]: true,
      })}
      onClick={handleOnClick}
    >
      {displayTitle && (
        <div className="teams-notifications__title">
          <span className="icon-teams teams-notifications__title__icon"></span>
          {displayTitleAsChannel ? (
            <a href={channels[0].channelUrl} target="_blank" rel="noopener noreferrer nofollow" className="h5 teams-notifications__title__text">
              {title}
            </a>
          ) : (
            <span
              className={cls({
                h5: type === 'page',
                'teams-notifications__title__text': true,
              })}
            >
              {title}
            </span>
          )}
        </div>
      )}
      {displayTitleAsChannel ? null : (
        <div className="teams-notification__content">
          {acceptedType && <span className="icon-close icon--md cmp-header__close-button" onClick={handleOnClick} />}
          {acceptedType && <h6 className="teams-notification__content__title">{popupTitle}</h6>}
          <div className="teams-notifications__channels">
            {type === 'list' ? (
              channels.map(channel => (
                <div key={channel.channelUrl} className="teams-notifications__channels__channel">
                  <a onClick={() => handleTracking(channel.title)} href={channel.channelUrl} target="_blank" rel="noopener noreferrer nofollow">
                    {channel.title}
                  </a>
                </div>
              ))
            ) : (
              <TagList data={channels} type="teams-notifications" handleTracking={handleTracking} />
            )}
          </div>
          <div className="teams-notifications__setup">
            <span className="icon-help-outline teams-notifications__setup__icon"></span>
            <a href={hintUrl} target="_blank" rel="noopener noreferrer nofollow" className="teams-notifications__setup__link">
              {hintText}
            </a>
          </div>
        </div>
      )}
    </div>
  )
}
