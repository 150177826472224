import { DynamicFieldType, ItemType } from '../FilterableListTypes'

export const getTableHeaders = (dynamicFields: DynamicFieldType[]) => ({
  sortable: dynamicFields.some(dynamicField => dynamicField.sortable),
  items: dynamicFields.map(dynamicField => ({ sortable: dynamicField.sortable, value: dynamicField.value, key: dynamicField.key })),
})

export const getTableRows = (items: ItemType[], dynamicFields: DynamicFieldType[], tableRowLinks = false) =>
  items?.map(item =>
    dynamicFields?.map(dynamicField => ({
      value: item.properties ? item.properties[dynamicField.key]?.join(', ') || '-' : item[dynamicField.key],
      link: dynamicField.link ? item.link : null,
      url: tableRowLinks ? item.url : null,
    })),
  )

type SortHeadersPropsType = {
  items: ItemType[]
  column: string
  order?: 'ASC' | 'DESC'
}

export const sortHeaders = ({ items, column, order = 'ASC' }: SortHeadersPropsType) =>
  items.sort((a, b) => {
    const firstItem = Array.isArray(a[column]) ? a[column].join(', ') : a[column]
    const secondItem = Array.isArray(b[column]) ? b[column].join(', ') : b[column]
    return (
      new Intl.Collator('en', { usage: 'sort', sensitivity: 'base', ignorePunctuation: true, numeric: true }).compare(
        firstItem?.toLowerCase() || '',
        secondItem?.toLowerCase() || '',
      ) * (order === 'ASC' ? 1 : -1)
    )
  })
