import Icon from '@react/Atoms/Icon'

import { ItemType } from '../../../FilterableList/FilterableListTypes'
import { SimpleEvent } from './SimpleEvent'

type SimpleEventsPropsType = {
  eventsPageLink?: string
  eventsPageLinkLabel?: string
  items: ItemType[]
  listTitle?: string
}

export const SimpleEvents = ({ items, listTitle }: SimpleEventsPropsType) => {
  if (!items) {
    return
  }

  return (
    <>
      {listTitle && (
        <div className="cmp-filterable-list__heading">
          <h2 className="cmp-filterable-list__heading-title">
            <Icon iconName={'calendar'} />
            {listTitle}
          </h2>
        </div>
      )}
      {items.length > 0 && (
        <div className="cmp-filterable-list__items">
          {items.map((item, index) => (
            <SimpleEvent key={`${index}-${item.title}`} item={item} />
          ))}
        </div>
      )}
    </>
  )
}
