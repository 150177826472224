import './FilterbarHeader.scss'

import { LAST_ACTIVE_FILTER } from '@react/ListWrapper/constants'
import { cls } from '@react/utils/classname'
import { useContext, useEffect } from 'react'

import { FilterbarContext } from '../FilterbarContext'
import { filterbarPushToDataLayer, getAnalyticsAttribute } from '../utils/filterbar-tracking'

export function FilterbarHeader() {
  const {
    translationsState,
    headerTitle,
    setHeaderTitle,
    activeDropdowns,
    setActiveDropdowns,
    selectedFilterState,
    isFilterbarOpened,
    setIsFilterbarOpened,
    onFilterChange,
    analyticsState,
    componentName,
  } = useContext(FilterbarContext)

  const isDropdownOpened = activeDropdowns?.find(activeDropdown => activeDropdown === selectedFilterState?.key)

  useEffect(() => {
    if ((isFilterbarOpened && !isDropdownOpened) || !isFilterbarOpened) {
      setHeaderTitle(translationsState?.filterbarTitle || 'Filter')
    }
  }, [isFilterbarOpened, isDropdownOpened])

  const onClickHandler = () => {
    onFilterChange({
      key: selectedFilterState?.key,
      value: selectedFilterState?.value,
      type: selectedFilterState?.type,
      subFilter: selectedFilterState?.subFilter,
    })

    filterbarPushToDataLayer({
      data: { property: selectedFilterState?.key, interactionType: 'button' },
      analytics: analyticsState,
      selection: selectedFilterState?.value,
    })

    if (!activeDropdowns?.find(activeDropdown => activeDropdown === selectedFilterState?.key)) {
      setIsFilterbarOpened(false)
    }
    sessionStorage.setItem(
      LAST_ACTIVE_FILTER,
      JSON.stringify(activeDropdowns?.filter(activeDropdown => activeDropdown !== selectedFilterState?.key)?.[0]) || '',
    )
    setActiveDropdowns(activeDropdowns?.filter(activeDropdown => activeDropdown !== selectedFilterState?.key))
  }

  const closeFiltersHandler = () => {
    onFilterChange({
      key: selectedFilterState?.key,
      value: selectedFilterState?.value,
      type: selectedFilterState?.type,
      subFilter: selectedFilterState?.subFilter,
    })
    filterbarPushToDataLayer({
      data: { property: selectedFilterState?.key, interactionType: 'button' },
      analytics: analyticsState,
      selection: selectedFilterState?.value,
    })
    setIsFilterbarOpened(false)
  }

  return (
    <div className="cmp-filterbar__header">
      <button
        data-dm={getAnalyticsAttribute({ analytics: analyticsState, componentName }) || null}
        onClick={onClickHandler}
        className={cls({
          'cmp-filterbar__header--is-back': true,
          'cmp-filterbar__header--is-hidden': isFilterbarOpened && !isDropdownOpened,
        })}
      >
        <span className="icon-keyboard_arrow_left icon--md"></span>
        {translationsState?.filterbarBack || 'Back'}
      </button>
      <span className="cmp-filterbar__header-title">{headerTitle}</span>
      <button
        data-dm={getAnalyticsAttribute({ analytics: analyticsState, componentName }) || null}
        onClick={closeFiltersHandler}
        className={cls({
          'cmp-filterbar__header--is-close': true,
          'cmp-filterbar__header--is-hidden': isFilterbarOpened && isDropdownOpened,
        })}
        aria-labelledby={translationsState?.filterbarClose || 'Close'}
      >
        <span className="icon-close icon--md"></span>
      </button>
    </div>
  )
}
