import { SubFilterSelectedItemType, SubFilterType } from '@react/Filterbar/Filters/FiltersTypes'

import { SubFilter } from './SubFilter'

type SubFiltersPropsType = {
  subFilters: SubFilterType[]
  selectedSubFilter?: SubFilterSelectedItemType
  resetSubFilter?: boolean
  onSelectSubFilter: (selectedSubFilter: SubFilterSelectedItemType) => void
}

export function SubFilters({ subFilters, selectedSubFilter, resetSubFilter, onSelectSubFilter }: SubFiltersPropsType) {
  return (
    <>
      {subFilters?.map(subFilter => (
        <SubFilter
          key={subFilter.key}
          subFilter={subFilter}
          resetSubFilter={resetSubFilter}
          selectedSubFilter={selectedSubFilter}
          onSelectSubFilter={onSelectSubFilter}
        />
      ))}
    </>
  )
}
