import { ResponsiveImageComponent } from '@components/image/responsiveimage/responsiveimage'
import { useEffect, useRef } from 'react'

type ImageWrapperProps = {
  modelJson?: string
  classes?: string
  imageSrc?: string
  imageAlt?: string
  backgroundImage?: string
}

export default function ImageWrapper({ modelJson, classes, imageSrc, imageAlt, backgroundImage }: ImageWrapperProps) {
  const imageRef = useRef(null)

  useEffect(() => {
    ResponsiveImageComponent(imageRef.current)
  }, [])

  return (
    <div ref={imageRef} className={classes} data-image-src={modelJson} style={backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}}>
      {imageSrc && (
        <picture>
          <img src={imageSrc} alt={imageAlt} />
        </picture>
      )}
    </div>
  )
}
