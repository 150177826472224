import './EventList.scss'

import React, { useContext } from 'react'

import { FilterableListContext } from '../../../FilterableList/FilterableListContext'
import { ItemType } from '../../../FilterableList/FilterableListTypes'
import { modifyEventItems } from '../../../FilterableList/utils/events'
import { EventListItem } from './EventList/EventListItem'
import { EventListLinks } from './EventList/EventListLinks'
import { EventListSectionHeader } from './EventList/EventListSectionHeader'

export const EventList = ({ items }: { items: ItemType[] }) => {
  const { propsState, translationsState } = useContext(FilterableListContext)
  const modifiedItems = modifyEventItems(items, propsState, translationsState)

  return (
    <>
      {propsState.displayPastEventsLink === 'true' && <EventListLinks />}
      {modifiedItems?.map(
        (modifiedItem, modifiedItemIndex) =>
          modifiedItem.items.length > 0 && (
            <React.Fragment key={`${modifiedItem.label}-${modifiedItemIndex}`}>
              <EventListSectionHeader label={modifiedItem.label} />
              {modifiedItem.items?.map((item, index) => <EventListItem key={`${index}-${item.title}`} item={item} />)}
            </React.Fragment>
          ),
      )}
    </>
  )
}
