import { getIsMobileSmall, getIsTablet } from '@site/js/utils/breakpoint'
import { breakpointValues } from '@site/js/utils/breakpoint'
import isObjectEmpty from '@site/js/utils/isObjectEmpty'

import getAspectRatioFromHyphenizedString from './getAspectRatioFromHyphenizedString'

export const REQUIRED_FORMAT = () => ({ format: 'webp-alpha', type: 'image/webp' })

const MOBILE = 'MOBILE'
const TABLET = 'TABLET'
const DESKTOP = 'DESKTOP'

const getCurrentViewport = () => {
  if (getIsMobileSmall()) {
    return MOBILE
  }

  if (getIsTablet()) {
    return TABLET
  }

  return DESKTOP
}

// Pixel Density. Eg. 2x is Retina
export const REQUIRED_SCALES = ['1x', '1.5x', '2x']

const AVAILABLE_WIDTHS = [320, 480, 600, 960, 1024, 1200, 1440, 1920, 2880]

const scaleToCover = (element, dimensions) => {
  const initialWidth = dimensions.width
  const initialHeight = dimensions.height

  if (dimensions.width > element.config.originalWidth) {
    dimensions.width = element.config.originalWidth
    dimensions.height = proportionalHeightFromWidth(dimensions.width, initialWidth, initialHeight)
  }

  if (dimensions.height > element.config.originalHeight) {
    dimensions.height = element.config.originalHeight
    dimensions.width = proportionalWidthFromHeight(dimensions.height, initialWidth, initialHeight)
  }

  return dimensions
}

export const optimizeDimensions = (element, configuration) => {
  const { initialWidth, initialHeight, scaleFactor } = configuration
  const newDimensions = {}
  const highestSupportedWidth = Math.max(...AVAILABLE_WIDTHS)
  const aspectRatioObject = isObjectEmpty(element.config?.aspectRatio) ? {} : element.config.aspectRatio
  const aspectRatioKeys = Object.keys(aspectRatioObject)
  const aspectRatioMapping = aspectRatioKeys?.reduce((acc, viewport) => {
    return {
      ...acc,
      [viewport]: {
        aspectRatioWidth: getAspectRatioFromHyphenizedString(aspectRatioObject, viewport).aspectRatioWidth,
        aspectRatioHeight: getAspectRatioFromHyphenizedString(aspectRatioObject, viewport).aspectRatioHeight,
      },
    }
  }, {})
  let nominalWidth = Math.ceil(initialWidth * scaleFactor) > highestSupportedWidth ? highestSupportedWidth : Math.ceil(initialWidth * scaleFactor)

  if (!element.config.originalWidth || element.config.originalWidth <= 0) {
    element.config.originalWidth = Math.ceil(nominalWidth)
  }

  if (!element.config.originalHeight || element.config.originalHeight <= 0) {
    element.config.originalHeight = isObjectEmpty(aspectRatioMapping)
      ? proportionalHeightFromWidth(nominalWidth, 16, 9)
      : proportionalHeightFromWidth(
          nominalWidth,
          aspectRatioMapping[getCurrentViewport()].aspectRatioWidth,
          aspectRatioMapping[getCurrentViewport()].aspectRatioHeight,
        )
  }

  const validWidths = AVAILABLE_WIDTHS.filter(n => {
    return n >= nominalWidth && n < element.config.originalWidth
  })

  if (validWidths.length > 0) {
    nominalWidth = Math.min(...validWidths)
  }

  if (isNaN(nominalWidth)) {
    nominalWidth = breakpointValues.upMd
  }

  newDimensions.width = Math.min(element.config.originalWidth, nominalWidth)
  newDimensions.height = isObjectEmpty(aspectRatioMapping)
    ? proportionalHeightFromWidth(newDimensions.width, initialWidth, initialHeight)
    : proportionalHeightFromWidth(
        newDimensions.width,
        aspectRatioMapping[getCurrentViewport()].aspectRatioWidth,
        aspectRatioMapping[getCurrentViewport()].aspectRatioHeight,
      )

  return scaleToCover(element, newDimensions)
}

export const proportionalHeightFromWidth = (width, initialWidth, initialHeight) => {
  const aspectRatio = initialWidth / initialHeight
  if (isNaN(aspectRatio) || aspectRatio === 0 || aspectRatio === Infinity) {
    initialWidth = 16
    initialHeight = 9
  }
  return Math.ceil((width * initialHeight) / initialWidth)
}

export const proportionalWidthFromHeight = (height, initialWidth, initialHeight) => {
  const aspectRatio = initialWidth / initialHeight
  if (isNaN(aspectRatio) || aspectRatio === 0 || aspectRatio === Infinity) {
    initialWidth = 16
    initialHeight = 9
  }

  return Math.ceil((height * initialWidth) / initialHeight)
}

export const imagePathFromPattern = (pattern, settings) => {
  const { width, height, format, aspectRatio } = settings

  const updatedPattern = aspectRatio ? pattern.replace(/\$\{aspectRatio\}/i, aspectRatio) : pattern.replace(/:\$\{aspectRatio\}/i, '')

  return updatedPattern
    .replace(/\$\{width\}/i, width)
    .replace(/\$\{height\}/i, height)
    .replace(/\$\{format\}/i, format)
}

export const failElement = (element, err) => {
  element.classList.add('in-fault')
  console.warn(`${err} while initialising ${element}`)
}
