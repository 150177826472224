import './FilterbarFooter.scss'

import { ActionButton } from '@react/Filterbar/ActionButton'
import { SELECT } from '@react/Filterbar/constants'
import { LAST_ACTIVE_FILTER } from '@react/ListWrapper/constants'
import { useContext } from 'react'

import { FilterbarContext } from '../FilterbarContext'
import { filterbarPushToDataLayer } from '../utils/filterbar-tracking'

export function FilterbarFooter() {
  const { activeDropdowns, analyticsState, selectButtonTitle, selectedFilterState, setIsFilterbarOpened, setActiveDropdowns, onFilterChange } =
    useContext(FilterbarContext)

  const isDropdownOpened = activeDropdowns?.find(activeDropdown => activeDropdown === selectedFilterState?.key)

  const onClickHandler = () => {
    onFilterChange({
      key: selectedFilterState?.key,
      value: selectedFilterState?.value,
      type: selectedFilterState?.type,
      subFilter: selectedFilterState?.subFilter,
    })

    filterbarPushToDataLayer({
      data: { property: selectedFilterState?.key, interactionType: 'button' },
      analytics: analyticsState,
      selection: selectedFilterState?.value,
    })

    if (!isDropdownOpened) {
      setIsFilterbarOpened(false)
    }
    sessionStorage.setItem(
      LAST_ACTIVE_FILTER,
      JSON.stringify(activeDropdowns?.filter(activeDropdown => activeDropdown !== selectedFilterState?.key)?.[0]) || '',
    )
    setActiveDropdowns(activeDropdowns?.filter(activeDropdown => activeDropdown !== selectedFilterState?.key))
  }

  return (
    <div className="cmp-filterbar__footer">
      <ActionButton buttonLabel={selectButtonTitle} buttonType={SELECT} onClickHandler={onClickHandler} />
    </div>
  )
}
