import { replaceLabelPlaceholders } from '@site/js/utils/utils'

import { DateAndTimeValuesType, ItemType, PropsType, TranslationsType } from '../FilterableListTypes'

export const isMultiDate = (startDate: string, endDate: string) => {
  if (endDate && endDate !== '') {
    return endDate.split(' ')[0] !== startDate.split(' ')[0]
  }
  return false
}

export const formatMonthFromObject = (date: Date, languageLocale = 'en-GB') => Intl.DateTimeFormat(languageLocale, { month: 'long' }).format(date)

export const getDateAndTimeValues = (dateString?: string): DateAndTimeValuesType => {
  if (!dateString) {
    return null
  }

  const splitDate = dateString.split(' ')
  const [date = 0, month = 0, year = 0] = splitDate?.[0]?.match(/\d+/g) || []
  const [hours = 0, minutes = 0, seconds = 0] = splitDate?.[1]?.match(/\d+/g) || []
  const dateObj = new Date(Date.UTC(Number(year), Number(month) - 1, Number(date), Number(hours), Number(minutes), Number(seconds)))
  const formatMonth = formatMonthFromObject(dateObj)

  return {
    date: Number(date),
    month: Number(month),
    year: Number(year),
    hours: Number(hours),
    minutes: Number(minutes),
    seconds: Number(seconds),
    formatMonth,
    dateObj,
    dateString,
  }
}

export const checkDelay = ({
  item,
  propsState,
  translationState,
  isTopEvent,
}: {
  item: ItemType
  propsState: PropsType
  translationState: TranslationsType
  isTopEvent: boolean
}): string => {
  const {
    date: dayStart = 0,
    month: monthStart = 0,
    year: yearStart = 0,
    hours: hourStart = 0,
    minutes: minuteStart = 0,
  } = !item.unscheduledEvent ? getDateAndTimeValues(item.startDate) : {}

  const {
    date: dayEnd = 0,
    month: monthEnd = 0,
    year: yearEnd = 0,
    hours: hourEnd = 0,
    minutes: minuteEnd = 0,
  } = !item.unscheduledEvent ? getDateAndTimeValues(item.endDate) : {}
  const dateStart = new Date(Date.UTC(+yearStart, +monthStart - 1, +dayStart)).getTime()
  const dateEnd = new Date(Date.UTC(+yearEnd, +monthEnd - 1, +dayEnd, +hourEnd, +minuteEnd)).getTime()
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  const daysStart = Math.floor((dateStart - now.getTime()) / (1000 * 60 * 60 * 24))
  const daysEnd = Math.floor((dateEnd - now.getTime()) / (1000 * 60 * 60 * 24))
  const minutesStart = Math.floor(
    (new Date(Date.UTC(Number(yearStart), Number(monthStart) - 1, Number(dayStart), Number(hourStart), Number(minuteStart))).getTime() -
      new Date().getTime()) /
      (1000 * 60 * 60),
  )
  const minutesEnd = Math.floor((dateEnd - new Date().getTime()) / (1000 * 60 * 60))

  const startAndEndDateObject = {
    daysStart,
    daysEnd,
    minutesStart,
    minutesEnd,
  }
  if (isTopEvent) {
    return `${propsState.displayTopEventAsTeaser === 'true' ? item.eventType : translationState.topEvent}`
  }

  return getBadgeString({ item, propsState, translationState, startAndEndDateObject })
}

const getBadgeString = ({ propsState, item, translationState, startAndEndDateObject }) => {
  const { daysStart, daysEnd, minutesStart, minutesEnd } = startAndEndDateObject
  if (daysStart <= 3 && daysEnd >= 0) {
    if (daysStart > 1) {
      return `${replaceLabelPlaceholders(translationState.countdownFuture, daysStart)}`
    } else if (daysStart === 1) {
      return `${translationState.countdownTomorrow}`
    } else if (daysStart <= 0 && daysEnd >= 0) {
      if (minutesStart > 0) {
        return `${translationState.countdownToday}`
      } else if (minutesStart <= 0 && minutesEnd > 0 && (item.isOngoing == null || item.isOngoing) && propsState.eventListType !== 'past_events')
        return `${translationState.ongoingEvent}`
    }
  }

  return ''
}

export const formatEventDate = (date = new Date()): string =>
  new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
    .format(date)
    .replace(',', '')
    .replace(/\//g, '.')
