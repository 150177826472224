import { ButtonListType } from '../FilterableListTypes'
import { ActionButton } from './ActionButton'

type EditableLinksPropsType = {
  editableLinks: ButtonListType[]
  buttonAlignment: string
}

export const EditableLinks = ({ editableLinks, buttonAlignment }: EditableLinksPropsType) => {
  return (
    <div className={`cmp-filterable-list__buttons-wrapper cmp-filterable-list__button--${buttonAlignment}`}>
      {editableLinks.map((editableLink, index) => (
        <ActionButton key={index} type="link" icon="keyboard_arrow_right" url={editableLink.link}>
          {editableLink.linkText || editableLink.label}
        </ActionButton>
      ))}
    </div>
  )
}
