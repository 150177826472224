import './Sortbar.scss'

import { ListViewSwitcher } from '@react/ListViewSwitcher/ListViewSwitcher'

import SortbarDropdown from './SortbarDropdown'
import { SortbarPropsType } from './SortbarTypes'
import { SortOrder } from './SortOrder'

export function Sortbar({
  listViewTemplates,
  sortable,
  displaySortOrder,
  selectedFiltersProp,
  translations,
  sortByOptions,
  onListViewSwitcher,
  onSortSelect,
}: SortbarPropsType) {
  let viewTemplates = null

  if (listViewTemplates) {
    try {
      viewTemplates = JSON.parse(listViewTemplates)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      {(sortable || displaySortOrder) && (
        <div className="cmp-sortbar">
          <SortOrder displaySortOrder={displaySortOrder} translations={translations} />
          <SortbarDropdown
            sortable={sortable}
            displaySortOrder={displaySortOrder}
            selectedFilters={selectedFiltersProp}
            translations={translations}
            sortByOptions={sortByOptions}
            onSortSelect={onSortSelect}
          />
          {viewTemplates && <ListViewSwitcher listViewTemplates={viewTemplates} onListViewSwitcher={onListViewSwitcher} />}
        </div>
      )}
    </>
  )
}
