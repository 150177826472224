import './Testimonial.scss'

import ImageWrapper from '@react/ImageWrapper'

import { ItemType } from '../FilterableListTypes'
import { Card } from '../UI/Card'

type TestimonialProps = {
  item: ItemType
  showTag?: boolean
  showMetaTag?: boolean
  showMetaDate?: boolean
  showMetaTimeToRead?: boolean
  showButton?: boolean
  buttonLabel?: string
}

export const Testimonial = ({
  item,
  showTag = false,
  showMetaTag = false,
  showMetaDate = false,
  showMetaTimeToRead = false,
  showButton = true,
  buttonLabel = '',
}: TestimonialProps) => {
  if (showMetaDate) {
    item.dateString = item.date.split(' ')?.[0]
  }

  return (
    <Card
      item={item}
      classes="cmp-filterable-list__card--testimonial-type"
      showMetaTag={showMetaTag}
      showMetaDate={showMetaDate}
      showMetaTimeToRead={showMetaTimeToRead}
      showTag={showTag}
      showButton={showButton}
      buttonLabel={buttonLabel}
    >
      <ImageWrapper
        modelJson={JSON.stringify(item.image)}
        classes="cmp-filterable-list__card-image cmp-responsive-image cmp-responsive-image--lazy"
        imageSrc={item.image.placeholder}
      />
    </Card>
  )
}
