import { ItemType } from '../FilterableListTypes'
import { Teaser } from './Teaser'

type TeaserListPropsType = {
  buttonLabel?: string
  items: ItemType[]
  showButton?: boolean
}

export const TeaserList = ({ items, buttonLabel = '', showButton = true }: TeaserListPropsType) => {
  return (
    <>
      {items?.map((item, index) => (
        <Teaser
          key={`${index}-${item.title}`}
          item={item}
          showTag={false}
          showMetaTag={false}
          showMetaDate={false}
          showMetaTimeToRead={false}
          showButton={showButton}
          buttonLabel={buttonLabel}
        />
      ))}
    </>
  )
}
