import { AnalyticsType } from '../Filters/FiltersTypes'

type AnalyticsAttribute = {
  analytics?: AnalyticsType
  componentName?: string
}

type DataLayerProps = {
  data: DataType
  analytics: AnalyticsType
  selection: string[]
}

type DataType = {
  dataset?: unknown
  label?: string
  property?: string
  interactionType?: string
}

type DataLayerEntry = {
  event: string
  eventSource: string
  action: string
  componentName: string
  interactionType: string
  data: unknown
  title: string
  property: string
  href: string
  selection: string[]
}

const pushDataLayer = (data: DataType, analytics: AnalyticsType, selection: string[]): void => {
  if (window.dataLayer) {
    const existingEntry = window.dataLayer.filter(entry => entry.property === data.property).slice(-1)
    if (existingEntry) {
      if ((existingEntry as DataLayerEntry[])?.[0]?.selection?.sort().toString() !== selection?.sort().toString()) {
        window.dataLayer.push({
          event: 'interaction',
          eventSource: 'aem',
          action: analytics.action,
          componentName: analytics.componentName,
          interactionType: data.interactionType,
          data: data.dataset || {},
          title: data.label || '',
          property: data.property || '',
          href: '',
          selection,
        })
      }
    } else {
      window.dataLayer.push({
        event: 'interaction',
        eventSource: 'aem',
        action: analytics.action,
        componentName: analytics.componentName,
        interactionType: data.interactionType,
        data: data.dataset || {},
        title: data.label || '',
        property: data.property || '',
        href: '',
        selection,
      })
    }
  }
}

// Values with format (for ex. 'workday-jobs:contract-types/Agency_Temp') needs to be modifed to match 'Agency_Temp'
// Analytics team wants those 'clean' values for their tracking dashboard
const cleanUpTheValuesForGtmAnalytics = (value: string): string =>
  value
    .replace(/\s\s+/g, '/')
    .replace(/[-\w]+:[-\w)]+\//g, '')
    .replace(/\//g, ',')

export const filterbarPushToDataLayer = (options: DataLayerProps): void => {
  const { data, analytics, selection } = options
  if (data && analytics) {
    const cleanValues = selection?.map(cleanUpTheValuesForGtmAnalytics)
    pushDataLayer(data, analytics, cleanValues)
  }
}

export const getAnalyticsAttribute = (analyticsConfig: AnalyticsAttribute, dmType = 'button'): string => {
  const hasSubmitButtonAnalyticsConfig = analyticsConfig?.analytics ? analyticsConfig.analytics.action === 'submit' : null
  return hasSubmitButtonAnalyticsConfig ? '' : `${analyticsConfig.componentName}.${dmType}`
}
