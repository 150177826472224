export const CalendarIconOnDemand = () => {
  return (
    <svg
      className="cmp-filterable-list__events-icon cmp-filterable-list__events-icon--single"
      aria-hidden="true"
      width="4.375rem"
      height="4.375rem"
      viewBox="0 0 70 70"
      xmlSpace="preserve"
    >
      <title>Pictograms/calendarcard_ondemand</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="64" height="64" rx="7"></rect>
        <filter x="-3.9%" y="-3.9%" width="110.9%" height="110.9%" filterUnits="objectBoundingBox" id="filter-2">
          <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
      </defs>
      <g id="Pictograms/calendarcard_singleday" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="calendarcard">
          <g id="shadow" fill="black" fillOpacity="1">
            <use filter="url(#filter-2)" xlinkHref="#path-1"></use>
          </g>
          <path
            d="M0,17.7777778 L64,17.7777778 L64,59 C64,61.7614237 61.7614237,64 59,64 L5,64 C2.23857625,64 3.38176876e-16,61.7614237 0,59 L0,17.7777778 L0,17.7777778 Z"
            id="bg_grey"
            fill="#F4F4F4"
          ></path>
          <path
            d="M5,1 L59,1 C61.7614237,1 64,3.23857625 64,6 L64,18.7777778 L64,18.7777778 L0,18.7777778 L0,6 C-1.2263553e-15,3.23857625 2.23857625,1 5,1 Z"
            id="bg_red"
            fill="#EB0023"
          ></path>
          <text className="cmp-filterable-list__events-icon-text cmp-filterable-list__events-icon-text--on-demand" x="32" y="37" textAnchor="middle">
            on
          </text>
          <text className="cmp-filterable-list__events-icon-text cmp-filterable-list__events-icon-text--on-demand" x="32" y="49" textAnchor="middle">
            demand
          </text>
        </g>
      </g>
    </svg>
  )
}
