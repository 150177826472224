/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'

import { ActiveFilter, AnalyticsType, FilterField, SelectedFilter, SubFilterSelectedItemType, TranslationsType } from './Filters/FiltersTypes'

type FilterbarContextType = {
  isFilterbarOpened: boolean
  setIsFilterbarOpened?: (params?: boolean) => void
  translationsState: TranslationsType
  setTranslationsState?: (params?: TranslationsType) => void
  headerTitle?: string
  setHeaderTitle?: (params: string) => void
  selectedFilterState: SelectedFilter
  setSelectedFilterState?: React.Dispatch<
    React.SetStateAction<{ key: string; value?: string[]; type?: string; subFilter?: SubFilterSelectedItemType }>
  >
  selectButtonTitle: string
  setSelectButtonTitle?: (params?: string) => void
  activeDropdowns: string[]
  setActiveDropdowns?: (params: string[]) => void
  isToggled?: boolean
  setIsToggled?: (params: boolean) => void
  selectedFilters: SelectedFilter[]
  setSelectedFilters: (params: SelectedFilter[]) => void
  filterFieldsState: FilterField[]
  setFilterFieldsState: (params: SelectedFilter[]) => void
  secondaryFilterFieldsState: FilterField[]
  setSecondaryFilterFieldsState: (params: SelectedFilter[]) => void
  totalNumberState: number
  setTotalNumberState: (params: number) => void
  activeFiltersState: ActiveFilter[]
  setActiveFiltersState: (params: ActiveFilter[]) => void
  onFilterChange: (selectedFilter: { key: string; value: string[]; type?: string; subFilter?: SubFilterSelectedItemType }) => void
  handleClearFilter: (filter: { key: string; value: string[]; type?: string; subFilter?: SubFilterSelectedItemType }) => void
  handleClearAllFilters: () => void
  analyticsState: AnalyticsType
  setAnalyticsState?: (params?: AnalyticsType) => void
  dataDm?: string
  componentName?: string
}

const defaultContext: FilterbarContextType = {
  isFilterbarOpened: false,
  setIsFilterbarOpened: (): void => {},
  translationsState: null,
  setTranslationsState: (): void => {},
  headerTitle: '',
  setHeaderTitle: (): void => {},
  selectedFilterState: null,
  setSelectedFilterState: (): void => {},
  selectButtonTitle: '',
  setSelectButtonTitle: (): void => {},
  activeDropdowns: [],
  setActiveDropdowns: (): void => {},
  isToggled: false,
  setIsToggled: (): void => {},
  selectedFilters: [],
  setSelectedFilters: (): void => {},
  filterFieldsState: [],
  setFilterFieldsState: (): void => {},
  secondaryFilterFieldsState: [],
  setSecondaryFilterFieldsState: (): void => {},
  totalNumberState: 0,
  setTotalNumberState: (): void => {},
  activeFiltersState: [],
  setActiveFiltersState: (): void => {},
  onFilterChange: (): void => {},
  handleClearFilter: (): void => {},
  handleClearAllFilters: (): void => {},
  analyticsState: null,
  setAnalyticsState: (): void => {},
  dataDm: null,
  componentName: null,
}

export const FilterbarContext = createContext(defaultContext)
