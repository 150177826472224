import './EventListLinks.scss'

import { FilterableListContext } from '@react/FilterableList/FilterableListContext'
import { useContext } from 'react'

export const EventListLinks = () => {
  const { propsState, translationsState } = useContext(FilterableListContext)

  const upcomingEventsLinkObject = propsState.upcomingeventsLink
    ? JSON.parse(propsState?.upcomingeventsLink)
    : { link: '', linkText: translationsState.seeUpcomingEvents }

  const pastEventsLinkObject = propsState.pasteventsLink
    ? JSON.parse(propsState?.pasteventsLink)
    : { link: '', linkText: translationsState.seePastEvents }

  const renderLink = (type: string) => {
    switch (type) {
      case 'upcoming_events': {
        return (
          <a className="cmp-filterable-list__list-events-link cmp-filterable-list__list-events-link--past-event" href={pastEventsLinkObject.link}>
            <span className={`cmp-button__icon icon-keyboard_arrow_left icon--sm`} aria-hidden={true}></span>
            {pastEventsLinkObject.linkText}
          </a>
        )
      }

      case 'past_events': {
        return (
          <a
            className="cmp-filterable-list__list-events-link cmp-filterable-list__list-events-link--upcoming-event"
            href={upcomingEventsLinkObject.link}
          >
            {upcomingEventsLinkObject.linkText}
            <span className={`cmp-button__icon icon-keyboard_arrow_right icon--xs`} aria-hidden={true}></span>
          </a>
        )
      }

      default:
        return <></>
    }
  }

  return renderLink(propsState.eventListType)
}
