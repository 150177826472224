import { cls } from '@react/utils/classname'
import { useContext } from 'react'

import { ButtonsWrapper } from '../Components/ButtonsWrapper'
import { TeaserList } from '../Components/TeaserList'
import { FilterableListContext } from '../FilterableListContext'
import { ItemType } from '../FilterableListTypes'
import { Table } from '../UI/Table'
import { getProductsTableHeaders, getProductsTableRows } from '../utils/products'

const columns = ['title', 'description']

export const Products = () => {
  const { propsState, responseData, translationsState } = useContext(FilterableListContext)
  const { items, totalNumber } = responseData
  const listStyleClassName = propsState.listStyle ? `cmp-filterable-list__events-type--${propsState.listStyle}` : ''
  const teaserColumnsClassName = propsState.teaserColumns ? `cmp-filterable-list--${propsState.teaserColumns}-columns` : ''

  const renderComponent = (items: ItemType[], listStyle = 'teaser') => {
    switch (listStyle) {
      case 'teaser':
        return <TeaserList buttonLabel={translationsState.readMoreLabel} items={items} />

      case 'table':
        return (
          <>
            <span className="cmp-filterable-list__results">{(translationsState.screenDisplayResults || '').replace('{0}', `${totalNumber}`)}</span>
            <Table
              classNames={'cmp-filterable-list cmp-filterable-list__products'}
              onTableRowClick={true}
              tableHeaders={getProductsTableHeaders(translationsState)}
              tableRows={getProductsTableRows(items, columns, columns[0])}
            />
          </>
        )

      default:
        return <p>List style do not exist</p>
    }
  }
  return (
    <div className="cmp-filterable-list">
      <div
        className={cls({
          'cmp-filterable-list__cards': true,
          [listStyleClassName]: propsState.listStyle,
          [teaserColumnsClassName]: propsState.teaserColumns && propsState.listStyle !== 'table',
        })}
      >
        {renderComponent(items, propsState.listStyle)}
      </div>
      <ButtonsWrapper />
    </div>
  )
}
