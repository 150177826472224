import './Checkbox.scss'

import { FilterbarContext } from '@react/Filterbar/FilterbarContext'
import { getAnalyticsAttribute } from '@react/Filterbar/utils/filterbar-tracking'
import { cls } from '@react/utils/classname'
import { useContext, useEffect } from 'react'

import { DropdownItems, SelectedCheckbox, SelectedFilter } from '../FiltersTypes'
import { useCheckbox } from './hooks'

type CheckboxProps = {
  item: DropdownItems
  buttonAriaLabel?: string
  isLoading?: boolean
  filterKey?: string
  selectedFilter?: SelectedFilter
  onSelectItem?: (selectedCheckbox: SelectedCheckbox[]) => void
}

export function Checkbox({ item, buttonAriaLabel, isLoading, filterKey, selectedFilter, onSelectItem }: CheckboxProps) {
  const {
    expandedCheckboxContainer,
    hasChidlren,
    isParent,
    handleOnKeyUp,
    handleOnKeyDown,
    handleOnClick,
    handleOnClickArrow,
    setExpandedCheckboxContainer,
  } = useCheckbox({
    item,
    filterKey,
    onSelectItem,
  })

  const { analyticsState, componentName } = useContext(FilterbarContext)

  useEffect(() => {
    if (!item) {
      return
    }

    if (!onSelectItem) {
      item.selected = selectedFilter?.key === filterKey && selectedFilter?.value?.includes(item.key)
    }

    // Select the parent if at least 1 child was selected
    if (item.children?.some(child => child.selected)) {
      item.selected = true
    }

    // Collapse parent checkbox if disabled
    if (item.disabled) {
      setExpandedCheckboxContainer(false)
    }
  }, [item, selectedFilter, filterKey])

  if (!item) {
    return null
  }

  return (
    <>
      <li
        onClick={e => handleOnClick(e, { key: hasChidlren || isParent ? '' : item.key, disabled: item.disabled })}
        className={cls({
          'cmp-filterbar__checkbox-item': true,
          'cmp-filterbar__checkbox-item--is-nested': !isParent,
        })}
      >
        <div className="cmp-filterbar__checkbox-item-wrapper">
          <div
            data-dm={getAnalyticsAttribute({ analytics: analyticsState, componentName }, 'select') || null}
            className={cls({
              'cmp-filterbar__checkbox-input': true,
              'cmp-filterbar__checkbox-input--is-checked': item.selected,
              'cmp-filterbar__checkbox-input--is-disabled': item.disabled || isLoading,
            })}
            role="checkbox"
            aria-checked={item.selected}
            aria-disabled={item.disabled}
            aria-label={item.value || item.label}
            onKeyUp={e => handleOnKeyUp(e, { key: hasChidlren || isParent ? '' : item.key, disabled: item.disabled })}
            onKeyDown={e => handleOnKeyDown(e, item.disabled)}
            tabIndex={item.disabled ? -1 : 0}
          >
            <span dangerouslySetInnerHTML={{ __html: item.value || item.label }}></span>
          </div>
          {hasChidlren && (
            <button
              onClick={e => handleOnClickArrow(e)}
              className={cls({
                'cmp-filterbar__checkbox-expand icon--md': true,
                'cmp-filterbar__checkbox-expand--is-disabled': item.disabled || isLoading,
                'icon-keyboard_arrow_up': expandedCheckboxContainer,
                'icon-keyboard_arrow_down': !expandedCheckboxContainer,
              })}
              aria-label={buttonAriaLabel}
              tabIndex={item.disabled ? -1 : 0}
            ></button>
          )}
        </div>
        {hasChidlren && (
          <ul
            className={cls({
              'cmp-filterbar__child-checkboxes': true,
              'cmp-filterbar__child-checkboxes--is-expanded': expandedCheckboxContainer,
            })}
          >
            {item.children?.map(child => (
              <Checkbox isLoading={isLoading} key={child.key} filterKey={filterKey} item={child} onSelectItem={onSelectItem} />
            ))}
          </ul>
        )}
      </li>
    </>
  )
}
