import './ActionButton.scss'

import { ReactNode, useContext } from 'react'

import { FilterableListContext } from '../FilterableListContext'

type ActionButtonType = {
  children?: ReactNode
  buttonType?: 'primary' | 'secondary'
  icon?: string
  iconSize?: 'sm' | 'md' | 'lg'
  onClick?: () => void
  type?: 'button' | 'link'
  url?: string
}

export const ActionButton = ({ type = 'button', buttonType = 'secondary', children, icon, iconSize = 'sm', onClick, url }: ActionButtonType) => {
  const { isLoading } = useContext(FilterableListContext)

  const renderButton = () => {
    if (type === 'button') {
      return (
        <>
          <button className={`cmp-button cmp-button--${buttonType}-positive ${isLoading ? 'cmp-button--is-loading' : ''}`} onClick={onClick}>
            {children}
            {icon && <span className={`cmp-button__icon icon-${icon} icon--${iconSize}`} aria-hidden={true}></span>}
          </button>
        </>
      )
    }

    return (
      <a className={`cmp-button cmp-button--${buttonType}-positive`} href={url}>
        {children}
        {icon && <span className={`cmp-button__icon icon-${icon} icon--${iconSize}`} aria-hidden={true}></span>}
      </a>
    )
  }

  return renderButton()
}
