import { replaceLabelPlaceholders } from '@site/js/utils/utils'

export type TemporalType = {
  day: string
  days: string
  hour: string
  hours: string
  justNow: string
  minute: string
  minutes: string
  timeAgo: string
}

export const formatDate = (d: string, temporal: TemporalType) => {
  const { justNow, minute, minutes, hour, hours, day, days, timeAgo } = temporal
  const date = new Date(d)
  const today = new Date()
  let dd: number | string = date.getDate()
  let mm: number | string = date.getMonth() + 1
  const yyyy = date.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  const msPassed = Math.abs(date.getTime() - today.getTime())
  const minutesPassed = Math.round(msPassed / (60 * 1000))
  const hoursPassed = Math.round(msPassed / (60 * 60 * 1000))
  const daysPassed = Math.round(msPassed / (24 * 60 * 60 * 1000))
  if (minutesPassed < 1) {
    return justNow
  }
  if (hoursPassed <= 1 && minutesPassed < 60) {
    return `${replaceLabelPlaceholders(timeAgo, minutesPassed, minutesPassed > 1 ? minutes : minute)}`
  }
  if (hoursPassed <= 24) {
    return `${replaceLabelPlaceholders(timeAgo, hoursPassed, hoursPassed > 1 ? hours : hour)}`
  }
  if (daysPassed <= 6) {
    return `${replaceLabelPlaceholders(timeAgo, daysPassed, daysPassed > 1 ? days : day)}`
  }
  return `${dd}.${mm}.${yyyy}`
}
