/* eslint-disable sonarjs/cognitive-complexity */
import { ActiveFilter, DropdownItems, FilterField, SelectedFilter, SubFilterSelectedItemType } from './Filters/FiltersTypes'

export const getActiveFilters = (
  filterFieldsState: FilterField[],
  secondaryFilterFieldsState: FilterField[],
  selectedFilters: SelectedFilter[],
): ActiveFilter[] => {
  const filterFieldsItems = [...(filterFieldsState ?? []), ...(secondaryFilterFieldsState ?? [])]
  const activeFilters: ActiveFilter[] = []

  filterFieldsItems?.map(filterFieldItem => {
    if (filterFieldItem.items) {
      if (filterFieldItem.type === 'rangeSlider') {
        selectedFilters?.map(selectedFilter => {
          if (selectedFilter.key === `${filterFieldItem.key}Type`) {
            if (!activeFilters.find(activeFilter => activeFilter.key === selectedFilter.value[0])) {
              activeFilters.push({
                item: {
                  key: selectedFilter.value[0],
                  value: selectedFilter.value[0],
                  type: filterFieldItem.type,
                  subFilter: { key: selectedFilter.key, value: selectedFilter.value },
                },
                key: filterFieldItem.key,
              })
            }
          } else {
            if (selectedFilter.key.startsWith(filterFieldItem.key) && !activeFilters.find(activeFilter => activeFilter.key === filterFieldItem.key)) {
              activeFilters.push({
                item: { key: filterFieldItem.key, value: filterFieldItem.label, type: filterFieldItem.type },
                key: filterFieldItem.key,
              })
            }
          }
        })
      } else {
        filterFieldItem?.items?.map(filterItem => {
          if (filterItem.children) {
            const allChildrenSelected = filterItem.children.every(child => child.selected)
            if (allChildrenSelected) {
              filterItem.selected = true
              activeFilters.push({ item: filterItem, key: filterFieldItem.key })
            } else {
              filterItem.children.forEach(child => {
                if (child.selected) {
                  activeFilters.push({ item: child, key: filterFieldItem.key })
                }
              })
            }
          } else if (filterItem.selected) {
            activeFilters.push({ item: filterItem, key: filterFieldItem.key })
          }
        })
      }
    } else {
      selectedFilters?.map(selectedFilter => {
        if (selectedFilter.key === filterFieldItem.key) {
          activeFilters.push({ item: selectedFilter, key: filterFieldItem.key })
        }
      })
    }
  })

  return activeFilters
}

export const addFilter = ({
  key,
  value,
  selectedFilters,
  setSelectedFilters,
}: {
  key: string
  value: string[]
  selectedFilters: SelectedFilter[]
  setSelectedFilters: (selectedFilters: SelectedFilter[]) => void
}) => setSelectedFilters([...selectedFilters, { key, value }])

export const updateSelectedFilters = ({
  key,
  value,
  selectedFilters,
  setSelectedFilters,
}: {
  key: string
  value: string[]
  selectedFilters: SelectedFilter[]
  setSelectedFilters: (selectedFilters: SelectedFilter[]) => void
}) => setSelectedFilters(selectedFilters.map((item: SelectedFilter) => (item.key === key ? { ...item, value } : item)))

export const removeFilter = ({
  key,
  selectedFilters,
  setSelectedFilters,
}: {
  key: string
  selectedFilters: SelectedFilter[]
  setSelectedFilters: (setSelectedFilters: SelectedFilter[]) => void
}) => setSelectedFilters(selectedFilters.filter((item: SelectedFilter) => item.key !== key))

export const handleClearActiveFilter = ({
  key,
  value,
  type,
  subFilter,
  selectedFilters,
  activeFiltersState,
  handleFilterChange,
}: {
  key: string
  value: string[]
  type?: string
  subFilter?: SubFilterSelectedItemType
  selectedFilters: SelectedFilter[]
  activeFiltersState: ActiveFilter[]
  handleFilterChange: (object: { key: string; value: string[]; type?: string; subFilter?: SubFilterSelectedItemType }) => void
}) => {
  let filterValue
  let subFilterValue
  if (type === 'rangeSlider') {
    const selectedRangeSubFilters = selectedFilters.find(item => item.key === `${key}Type`)
    const selectedRangeFilters = selectedFilters
      .filter(item => item.key.startsWith(key) && item.key !== `${key}Type`)
      .map(item => item.value)
      .flat()
    filterValue = subFilter ? selectedRangeFilters : []
    subFilterValue = subFilter ? { ...selectedRangeSubFilters, value: [''] } : selectedRangeSubFilters
  } else {
    const activeFilterWithChildren = activeFiltersState
      .filter(activeFilter => activeFilter.item.key !== value[0])
      .filter(activeFilter => (activeFilter.item as DropdownItems).children)
      .flatMap(activeFilter => [
        activeFilter,
        ...(activeFilter?.item as DropdownItems).children.map(child => ({ item: child, key: activeFilter.key })),
      ])

    const activeFilterWithoutChildren = activeFiltersState.filter(activeFilter => !(activeFilter.item as DropdownItems).children)

    const finalActiveFilters = [...activeFilterWithoutChildren, ...activeFilterWithChildren]

    filterValue = finalActiveFilters
      .filter((activeFilter: ActiveFilter) => activeFilter.key === key)
      .filter(updatedActiveFilter => updatedActiveFilter.item.key !== value[0])
      .map(value => value.item.key)
  }

  handleFilterChange({ key, value: filterValue, type, subFilter: subFilterValue })
}
