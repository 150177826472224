export default function getAspectRatioFromHyphenizedString(
  aspectRatio: Record<string, string>,
  viewport: 'MOBILE' | 'TABLET' | 'DESKTOP' | string,
): Record<'aspectRatioWidth' | 'aspectRatioHeight', number> {
  if (!viewport || !aspectRatio || !aspectRatio[viewport]) {
    return { aspectRatioWidth: 16, aspectRatioHeight: 9 }
  }
  const splitHyphen = aspectRatio[viewport].split('-')
  const aspectRatioWidth = parseInt(splitHyphen?.[0])
  const aspectRatioHeight = parseInt(splitHyphen?.[1])
  return { aspectRatioWidth, aspectRatioHeight }
}
