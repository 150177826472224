import './TagItem.scss'

import { ABBREVIATED, CLICKABLE, NON_CLICKABLE, REMOVABLE, TEAMS_NOTIFICATIONS } from '../constants'

type TagItemProps = {
  title: string
  href?: string
  handleTracking?: (param: string) => void
  type?: string
  property?: string
  key?: string
  endpointUrl?: string
  filterKey?: string
  listId?: string
}

export function TagItem({ title, href = null, handleTracking, type, property, key, endpointUrl, filterKey, listId }: TagItemProps) {
  return (
    <>
      {(type === NON_CLICKABLE || type === ABBREVIATED) && <li className="cmp-taglist__item">{title}</li>}
      {type === TEAMS_NOTIFICATIONS && (
        <li className={`cmp-taglist__item`}>
          <a className="cmp-taglist__item-link" onClick={() => handleTracking(title)} href={href} target="_blank" rel="noopener noreferrer nofollow">
            {title}
          </a>
        </li>
      )}
      {type === REMOVABLE && (
        <li className={`cmp-taglist__item`}>
          <button className="cmp-taglist__item-button" data-property={property} data-key={key} data-value={title}>
            <span className="cmp-taglist__item-text">{title}</span>
            <span className="cmp-taglist__item-icon icon-close" />
          </button>
        </li>
      )}
      {type === CLICKABLE && (
        <li className={`cmp-taglist__item`}>
          <a className="cmp-taglist__item-link" href={`${endpointUrl}?filterable${listId}-${filterKey}=${href}`} onClick={() => handleTracking(title)}>
            {title}
          </a>
        </li>
      )}
    </>
  )
}
