import { FilterFieldsType, ItemType, TranslationsType } from '../FilterableListTypes'

const getFilterFieldLabelByKey = (filterFields: FilterFieldsType, filterFieldKey: string): string => {
  return filterFields.find(filterField => filterField.key === filterFieldKey)?.label || filterFieldKey
}

const formattedLocation = (location: string) =>
  location
    .split('\n')
    .map(item => item.split(' ,').join(', '))
    .join('\r\n')

export const jobsTableHeaders = (translations: TranslationsType, filterFields: FilterFieldsType) => {
  return {
    items: [
      { value: translations.jobTitle },
      { value: translations.location },
      { value: getFilterFieldLabelByKey(filterFields, 'jobTypeTags') },
      { value: getFilterFieldLabelByKey(filterFields, 'contractTypeTags') },
      { value: getFilterFieldLabelByKey(filterFields, 'experienceTags') },
      { value: getFilterFieldLabelByKey(filterFields, 'jobFunctionTags') },
    ],
  }
}

export const jobsTableRows = (items: ItemType[]) =>
  items?.map(item => [
    { value: item.title, link: item.url },
    { value: formattedLocation(item.location) },
    { value: item.jobType },
    { value: item.contractType },
    { value: item.experience },
    { value: item.jobFunction },
  ])
