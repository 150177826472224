import './AdditionalFilters.scss'

import { cls } from '@react/utils/classname'
import { useContext } from 'react'

import { FilterbarContext } from '../FilterbarContext'

export function AdditionalFilters() {
  const { isToggled, setIsToggled, translationsState } = useContext(FilterbarContext)
  const handleToggleClick = () => {
    setIsToggled(!isToggled)
  }

  return (
    <div
      onClick={handleToggleClick}
      className={cls({
        'cmp-filterbar__secondary-filters-toggle': true,
        'cmp-filterbar__secondary-filters-toggle--is-toggled': isToggled,
      })}
    >
      <span className="cmp-filterbar__secondary-filters-toggle-title">{translationsState?.filterbarAdditional || 'More filter options'}</span>
    </div>
  )
}
