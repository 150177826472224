import './ActiveFilters.scss'

import { replaceLabelPlaceholders } from '@site/js/utils/utils'
import { useContext } from 'react'

import { FilterbarContext } from '../FilterbarContext'
import { Tags } from './Tags'

export function ActiveFilters() {
  const { translationsState, activeFiltersState, totalNumberState } = useContext(FilterbarContext)

  const numberOfItemsLabel = () => {
    if (activeFiltersState.length > 0) {
      return totalNumberState === 1 ? translationsState?.filterbarSubSingular || 'item' : translationsState?.filterbarSubPlural || 'items'
    } else {
      return translationsState?.filterbarSubItems || 'items'
    }
  }

  return (
    <div className="cmp-filterbar__active-filters">
      <p className="cmp-filterbar__count">{replaceLabelPlaceholders(numberOfItemsLabel(), totalNumberState)}</p>
      {activeFiltersState.length > 0 && <Tags />}
    </div>
  )
}
