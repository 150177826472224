import './AssetSearch.scss'

import { cls } from '@react/utils/classname'
import { useContext } from 'react'

import { AssetSearchButtons } from '../Components/AssetSearch/AssetSearchButtons'
import { AssetSearchInfo } from '../Components/AssetSearch/AssetSearchInfo'
import { ButtonsWrapper } from '../Components/ButtonsWrapper'
import { Teaser } from '../Components/Teaser'
import { FilterableListContext } from '../FilterableListContext'
import { ItemType } from '../FilterableListTypes'
import { Table } from '../UI/Table'
import { getAssetSearchTableHeaders, getAssetShareTableRows, isAssetInCart } from '../utils/asset-search'

export const AssetSearch = () => {
  const { responseData, propsState, translationsState } = useContext(FilterableListContext)
  const { items } = responseData
  const listViewTemplate = propsState.listStyleTemplate || propsState.listStyle
  const assetSearchClass = `cmp-filterable-list__asset-search-${listViewTemplate}`
  const teaserColumnsClass = `cmp-filterable-list--${propsState.teaserColumns || 3}-columns`

  const renderComponent = (items: ItemType[], listStyle = 'teaser') => {
    switch (listStyle) {
      case 'teaser':
        return items?.map((item, index) => (
          <Teaser
            key={`${index}-${item.title}`}
            showMetaDate={false}
            showMetaTag={false}
            showMetaTimeToRead={false}
            showTag={false}
            showButton={false}
            imageClasses={item?.objectFit ? 'cmp-filterable-list__card-image--no-object-fit' : ''}
            item={item}
            additionalTemplate={
              <div className="cmp-filterable-list__asset-search--content">
                <AssetSearchInfo item={item} />
                <AssetSearchButtons item={item} isAssetInCart={isAssetInCart(item)} />
              </div>
            }
          />
        ))
      case 'table':
        return (
          <Table
            customTableDataTemplate={item => <AssetSearchButtons isAssetInCart={isAssetInCart(item)} item={item} />}
            tableHeaders={getAssetSearchTableHeaders(translationsState)}
            tableRows={getAssetShareTableRows(items)}
          />
        )
      default:
        return <p>List style do not exist</p>
    }
  }

  return (
    <div className="cmp-filterable-list">
      <div
        className={cls({
          'cmp-filterable-list__asset-search cmp-filterable-list__cards': true,
          [assetSearchClass]: true,
          [teaserColumnsClass]: listViewTemplate !== 'table',
        })}
      >
        {renderComponent(items, listViewTemplate)}
      </div>
      <ButtonsWrapper />
    </div>
  )
}
