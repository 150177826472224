import { ItemType } from '@react/FilterableList/FilterableListTypes'
import { cls } from '@react/utils/classname'

export const AssetSearchButtons = ({ item, isAssetInCart = false }: { item: ItemType; isAssetInCart: boolean }) => (
  <div className="cmp-filterable-list__asset-search--action-buttons">
    <button
      aria-label="Download"
      className="cmp-button"
      data-asset-share-id="download-asset"
      data-asset-share-asset={item.assetShareUrl}
      data-tracking-enabled="click"
      data-tracking-interaction-type="download"
    >
      <span className="cmp-button__icon cmp-button__icon--download"></span>
    </button>
    <button
      aria-label="Add to cart"
      className={cls({ 'cmp-button': true, hidden: isAssetInCart })}
      data-asset-share-id="add-to-cart"
      data-asset-share-asset={item.assetShareUrl}
      data-tracking-enabled="click"
      data-tracking-interaction-type="add-to-cart"
    >
      <span className="cmp-button__icon cmp-button__icon--cart"></span>
    </button>
    <button
      aria-label="Remove from cart"
      className={cls({ 'cmp-button': true, hidden: !isAssetInCart })}
      data-asset-share-id="remove-from-cart"
      data-asset-share-asset={item.assetShareUrl}
      data-tracking-enabled="click"
      data-tracking-interaction-type="remove-from-cart"
    >
      <span className="cmp-button__icon cmp-button__icon--removecart"></span>
    </button>
    <button
      aria-label="Share asset"
      className="cmp-button"
      data-asset-share-id="share-asset"
      data-asset-share-asset={item.assetShareUrl}
      data-tracking-enabled="click"
      data-tracking-interaction-type="share"
    >
      <span className="cmp-button__icon cmp-button__icon--share"></span>
    </button>
  </div>
)
