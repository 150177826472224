import Abbreviation from '@react/Abbreviation'

import { DynamicFieldType, ItemType } from '../../FilterableListTypes'

type FragmentListTableDataPropsType = {
  dynamicField: DynamicFieldType
  item: ItemType
}

const abbrStringMaxLength = 90
const abbrMaxItemsAsArray = 3

export const FragmentsListTableData = ({ dynamicField, item }: FragmentListTableDataPropsType) => {
  const renderLink = (dynamicField: DynamicFieldType) => {
    const linkUrl = dynamicField.key === 'csafLink' ? item.csafLink : item.link
    const linkText =
      dynamicField.key === 'csafLink' ? <span className="cmp-button__icon cmp-button__icon--download"></span> : item?.title || item.fileName

    return (
      <a href={linkUrl} className="cmp-filterable-list__list-link" target="_blank" rel="noopener noreferrer">
        {linkText}
      </a>
    )
  }

  const renderFieldArrayAsAbbreviation = (item: ItemType, dynamicField: DynamicFieldType) => {
    const displayedItems = item[dynamicField.key].slice(0, abbrMaxItemsAsArray)

    if (item[dynamicField.key].length > abbrMaxItemsAsArray) {
      const value = displayedItems.join('/')
      const title = item[dynamicField.key].join('/')
      return <Abbreviation title={title} value={value} isArray={'true'} abbrString={'...'} toolTipType={'tooltip'} />
    }
  }

  if (dynamicField.link || dynamicField.key === 'csafLink') {
    return renderLink(dynamicField)
  } else if (Array.isArray(item[dynamicField.key]) && item[dynamicField.key].length > 1) {
    return renderFieldArrayAsAbbreviation(item, dynamicField)
  } else if (item[dynamicField.key]?.length > abbrStringMaxLength) {
    return (
      <Abbreviation
        title={item[dynamicField.key]}
        value={item[dynamicField.key].slice(0, abbrStringMaxLength).trim()}
        abbrString={'...'}
        toolTipType={'tooltip'}
      />
    )
  } else {
    return item[dynamicField.key]
  }
}
