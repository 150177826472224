import { Checkbox } from '../Checkbox'
import { Dropdown } from '../Dropdown'
import { FilterField, SelectedFilter } from '../FiltersTypes'
import { SearchInput } from '../SearchInput'

type FilterSwitcherPropsType = {
  filterField: FilterField
  selectedFilter: SelectedFilter
  isLoading: boolean
}

export function FilterSwitcher({ selectedFilter, isLoading, filterField }: FilterSwitcherPropsType) {
  const renderComponent = (type: string) => {
    switch (type) {
      case 'text':
        return <SearchInput isLoading={isLoading} label={filterField.label} filterKey={filterField.key} selectedFilter={selectedFilter} />

      case 'dropdown':
        return (
          <Dropdown
            isLoading={isLoading}
            label={filterField.label}
            dropdownKey={filterField.key}
            items={filterField.items}
            selectedFilter={selectedFilter}
            hasSearch={filterField.hasSearch}
            disabled={filterField.disabled}
          />
        )

      case 'rangeSlider':
        return (
          <Dropdown
            dropdownType="rangeslider"
            isLoading={isLoading}
            label={filterField.label}
            dropdownKey={filterField.key}
            items={filterField.items}
            selectedFilter={selectedFilter}
            hasSecondaryDropdown={filterField.hasSecondaryDropdown}
            hasSearch={filterField.hasSearch}
            disabled={filterField.disabled}
            unit={filterField.unit}
            subFilters={filterField.subFilters}
          />
        )

      case 'checkbox':
        return (
          <Checkbox
            isLoading={isLoading}
            selectedFilter={selectedFilter}
            filterKey={filterField.quickFilterType || filterField.key}
            item={filterField}
          />
        )

      default:
        return <></>
    }
  }
  return (
    filterField.type && (
      <div className={`cmp-filterbar__filter cmp-filterbar__filter-${filterField.type === 'rangeSlider' ? 'dropdown' : filterField.type} ${filterField.key === 'onDemand' ? 'cmp-filterbar__filter--is-on-demand' : ''}`}>
        {renderComponent(filterField.type)}
      </div>
    )
  )
}
