import { cls } from '@react/utils/classname'
import { useContext } from 'react'

import { ButtonsWrapper } from '../Components/ButtonsWrapper'
import { Teaser } from '../Components/Teaser'
import { Testimonial } from '../Components/Testimonial'
import { FilterableListContext } from '../FilterableListContext'
import { ItemType } from '../FilterableListTypes'
import { Card } from '../UI/Card'
import { ListTypeItem } from '../UI/ListTypeItem'

export const News = () => {
  const { responseData, propsState, translationsState } = useContext(FilterableListContext)
  const { items } = responseData
  const listStyleClassName = propsState.listStyle ? `cmp-filterable-list__news-type--${propsState.listStyle}` : ''
  const teaserColumnsClassName = propsState.teaserColumns ? `cmp-filterable-list--${propsState.teaserColumns}-columns` : ''

  const renderComponent = (item: ItemType, index: number, listStyle = 'teaser') => {
    switch (listStyle) {
      case 'teaser':
        return <Teaser item={item} key={`${index}-${item.title}`} buttonLabel={translationsState.readMoreLabel} />

      case 'card':
        return <Card item={item} key={`${index}-${item.title}`} showMetaDate={true} buttonLabel={translationsState.readMoreLabel} />

      case 'list':
        return <ListTypeItem item={item} key={`${index}-${item.title}`} showButton={true} buttonLabel={translationsState.readMoreLabel} />

      case 'testimonial':
        return <Testimonial item={item} key={`${index}-${item.title}`} buttonLabel={translationsState.readMoreLabel} />

      default:
        return <p>List style do not exist</p>
    }
  }
  return (
    <div className="cmp-filterable-list">
      <div
        className={cls({
          'cmp-filterable-list__list': propsState.listStyle === 'list',
          'cmp-filterable-list__cards': propsState.listStyle !== 'list',
          [listStyleClassName]: propsState.listStyle,
          [teaserColumnsClassName]: propsState.teaserColumns,
        })}
      >
        {items?.map((item, index) => {
          return renderComponent(item, index, propsState.listStyle)
        })}
      </div>
      <ButtonsWrapper url={propsState.seeAllLink} linkText={translationsState.filterbarShowAll} />
    </div>
  )
}
