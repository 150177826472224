import { cls } from '@react/utils/classname'

type EventCarouselPaginationProps = {
  slides: HTMLLIElement[]
  ariaLabel: string
  goToSlide: (index: number) => void
}

export const EventCarouselPagination = ({ slides, ariaLabel, goToSlide }: EventCarouselPaginationProps) => {
  const handleOnClick = (slide: number) => goToSlide(slide)

  return (
    <ul className="cmp-carousel__pagination" role="tablist" aria-label={ariaLabel}>
      {slides?.map((slide, index) => (
        <li key={index} role="presentation" className="cmp-carousel__pagination-item" onClick={() => handleOnClick(index)}>
          <button
            className={cls({
              'cmp-carousel__pagination-item--button': true,
              'is-active': slide.classList.contains('is-active'),
              'is-paginationactive': slide.classList.contains('is-paginationactive'),
            })}
            type="button"
            role="tab"
            aria-label={`Go to slide ${index + 1}`}
          ></button>
        </li>
      ))}
    </ul>
  )
}
