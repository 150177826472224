import './CalendarIcon.scss'

import { ItemType } from '../FilterableListTypes'
import { getDateAndTimeValues, isMultiDate } from '../utils/dates'
import { CalendarIconMultiple } from './CalendarIcons/CalendarIconMultiple'
import { CalendarIconOnDemand } from './CalendarIcons/CalendarIconOnDemand'
import { CalendarIconSingle } from './CalendarIcons/CalendarIconSingle'

type CalendarIconProps = {
  item: ItemType
  unscheduledEventsUpcomingLabel?: string
}

export const CalendarIcon = ({ item, unscheduledEventsUpcomingLabel = '' }: CalendarIconProps) => {
  const { date, year, formatMonth } = !item.unscheduledEvent ? getDateAndTimeValues(item.startDate) : {}
  const isMultiDay = isMultiDate(item.startDate, item.endDate)
  const currentYear = new Date().getFullYear()

  const renderCalendarIcon = (isUnscheduledEvent: boolean, isOnDemand: boolean, isMultiDay: boolean) => {
    if (isUnscheduledEvent) {
      return <CalendarIconSingle date="" month={unscheduledEventsUpcomingLabel} year={currentYear.toString()} />
    }

    if (isOnDemand) {
      return <CalendarIconOnDemand />
    }

    if (isMultiDay) {
      return <CalendarIconMultiple date={`${date}`} month={formatMonth} year={`${year}`} />
    }

    return <CalendarIconSingle date={`${date}`} month={formatMonth} year={`${year}`} />
  }

  return renderCalendarIcon(item.unscheduledEvent, item.onDemand, isMultiDay)
}
