import { createContext } from 'react'

import { PropsType, ResponseDataType, TranslationsType } from './FilterableListTypes'

type FilterableListContextType = {
  isLoading: boolean
  onLoadMore?: () => void
  propsState: PropsType
  responseData: ResponseDataType
  translationsState: TranslationsType
}

const defaultContext: FilterableListContextType = {
  isLoading: false,
  onLoadMore: (): void => {},
  propsState: null,
  responseData: null,
  translationsState: null,
}

export const FilterableListContext = createContext(defaultContext)
