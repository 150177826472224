import './SimpleEvent.scss'

import { ItemType } from '../../../FilterableList/FilterableListTypes'
import { EventListItem } from './EventList/EventListItem'

type SimpleEventPropsType = {
  item: ItemType
}

export const SimpleEvent = ({ item }: SimpleEventPropsType) => {
  return <EventListItem item={item} />
}
