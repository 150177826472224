import './ActionButton.scss'

import { cls } from '@react/utils/classname'
import { useContext } from 'react'

import { FilterbarContext } from '../FilterbarContext'
import { getAnalyticsAttribute } from '../utils/filterbar-tracking'

type ActionButtonPropsType = {
  buttonLabel: string
  buttonType: 'select' | 'reset' | 'clear-all' | 'tag'
  onClickHandler: () => void
  iconName?: string
  iconSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  iconPlacement?: 'before-label' | 'after-label'
  buttonAriaLabel?: string
}

export function ActionButton({
  buttonLabel,
  buttonType = 'select',
  onClickHandler,
  iconName,
  iconSize = 'sm',
  iconPlacement = 'before-label',
  buttonAriaLabel,
}: ActionButtonPropsType) {
  const { analyticsState, componentName } = useContext(FilterbarContext)

  return (
    <button
      data-dm={getAnalyticsAttribute({ analytics: analyticsState, componentName }) || null}
      onClick={onClickHandler}
      className={cls({
        'cmp-filterbar__button': true,
        [`cmp-filterbar__button--${buttonType}`]: Boolean(buttonType),
      })}
      {...(buttonAriaLabel ? { 'aria-label': buttonAriaLabel } : {})}
    >
      {iconName && <span className={`icon--${iconSize} icon-${iconName} icon-placement--${iconPlacement}`} />}
      <span className="cmp-filterbar__button-text">{buttonLabel}</span>
    </button>
  )
}
