import { TranslationsType } from '@react/Filterbar/Filters/FiltersTypes'

import { SortByOptions } from '../SortbarTypes'

export const getSortOptions = (sortByOptions: SortByOptions[], translations: TranslationsType) =>
  sortByOptions
    ? sortByOptions
    : [
        { id: 1, value: { order: 'desc', sort: 'date' }, label: `${translations?.sortBy} ${translations?.dateDesc}` },
        { id: 2, value: { order: 'asc', sort: 'date' }, label: `${translations?.sortBy} ${translations?.dateAsc}` },
        { id: 3, value: { order: 'asc', sort: 'title' }, label: `${translations?.sortBy} ${translations?.titleAsc}` },
        { id: 4, value: { order: 'desc', sort: 'title' }, label: `${translations?.sortBy} ${translations?.titleDesc}` },
      ]
