import './RangeSlider.scss'

import { FilterbarContext } from '@react/Filterbar/FilterbarContext'
import { getRangeSliderSearchParameters } from '@react/Filterbar/Filters/RangeSlider/getRangeSliderSearchParameters'
import { useContext, useEffect, useMemo, useState } from 'react'

import { SelectedFilter, SubFilterSelectedItemType } from '../FiltersTypes'
import { BarChart } from './BarChart'
import { MultiSlider } from './MultiSlider/MultiSlider'
import { MinMaxType, RangeSliderPropsType } from './RangeSliderTypes'
import { SubFilters } from './SubFilters'

const sortByKeyAsc = (data: Record<string, string>[]): Record<string, string>[] => {
  return data?.sort((a, b) => (parseInt(a.key) > parseInt(b.key) ? 1 : -1)) || []
}

export function RangeSlider({
  items,
  rangeSliderKey,
  selectedFilter,
  resetRangeSlider,
  setResetRangeSlider,
  unit,
  subFilters,
}: RangeSliderPropsType) {
  const sortedItems = useMemo(() => sortByKeyAsc(items), [items])
  const keyToStepMapping = useMemo(() => sortedItems.map(item => parseInt(item.key)), [sortedItems])
  const defaultMin = parseInt(sortedItems[0]?.key)
  const defaultMax = parseInt(sortedItems[sortedItems.length - 1]?.key)
  const from = getRangeSliderSearchParameters(rangeSliderKey)['from']
  const to = getRangeSliderSearchParameters(rangeSliderKey)['to']
  const minVal = parseInt(from) || defaultMin
  const maxVal = parseInt(to) || defaultMax
  const [minMax, setMinMax] = useState({ min: minVal, max: maxVal })
  const [resetSubFilter, setResetSubFilter] = useState(resetRangeSlider || false)
  const [selectedSubFilterState, setSelectedSubFilterState] = useState(selectedFilter?.subFilter)
  const [isLoadedRangeSlider, setIsLoadedRangeSlider] = useState(false)
  const [resetFilter, setResetFilter] = useState(false)
  const [initialFromToParams, setInitialFromToParams] = useState(null)
  const { setSelectedFilterState } = useContext(FilterbarContext)

  useEffect(() => {
    setIsLoadedRangeSlider(true)
  }, [])

  useEffect(() => {
    setMinMax({ min: defaultMin, max: defaultMax })
    setInitialFromToParams({ from: parseInt(from), to: parseInt(to) })
  }, [items?.length])

  useEffect(() => {
    onChangeHandler(minMax)
  }, [minMax])

  useEffect(() => {
    if (resetRangeSlider) {
      resetState()
      setResetRangeSlider(false)
    }
  }, [resetRangeSlider])

  useEffect(() => {
    if (isLoadedRangeSlider) {
      setResetSubFilter(!selectedFilter?.subFilter)
    }
  }, [selectedFilter?.subFilter])

  useEffect(() => {
    if (isLoadedRangeSlider) {
      setResetRangeSlider(!selectedFilter?.value)
    }
  }, [selectedFilter?.value])

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onChangeHandler = (selectedMinMax: MinMaxType) => {
    const filterStateObject = {
      key: rangeSliderKey,
      type: 'rangeslider',
      subFilter: selectedSubFilterState,
    }
    const rangeSliderValuesChanged = selectedFilter
      ? parseInt(selectedFilter.value[0]) !== selectedMinMax.min || parseInt(selectedFilter.value[1]) !== selectedMinMax.max
      : selectedMinMax.min !== minVal || selectedMinMax.max !== maxVal

    const hasInitialFromToParams = initialFromToParams?.from && initialFromToParams?.to

    if (selectedFilter) {
      let value = []

      if (rangeSliderValuesChanged && resetFilter && hasInitialFromToParams) {
        value = [String(initialFromToParams.from), String(initialFromToParams.to)]
      }

      if ((rangeSliderValuesChanged && !resetFilter) || (!rangeSliderValuesChanged && resetFilter && hasInitialFromToParams)) {
        value = [String(selectedMinMax.min), String(selectedMinMax.max)]
      }

      setSelectedFilterState({
        ...filterStateObject,
        value,
      })
    } else {
      setSelectedFilterState({
        ...filterStateObject,
        value: rangeSliderValuesChanged || !resetFilter ? [String(selectedMinMax.min), String(selectedMinMax.max)] : [],
      })
    }
    setResetFilter(false)
  }

  const resetState = () => {
    setResetFilter(true)
    setMinMax({ min: defaultMin, max: defaultMax })
  }

  const selectSubFilterHandler = ({ key, value }: SubFilterSelectedItemType) => {
    setSelectedSubFilterState({ key, value })
    setSelectedFilterState((prevState: SelectedFilter) => ({
      ...prevState,
      subFilter: { key, value },
    }))
  }

  return (
    <div className="cmp-rangeslider">
      <SubFilters
        subFilters={subFilters}
        resetSubFilter={resetRangeSlider || resetSubFilter}
        selectedSubFilter={selectedSubFilterState}
        onSelectSubFilter={selectSubFilterHandler}
      />
      <BarChart data={sortedItems} minMax={minMax}></BarChart>
      <MultiSlider
        keyToStepMapping={keyToStepMapping}
        min={defaultMin}
        max={defaultMax}
        onChange={setMinMax}
        minMax={minMax}
        unit={unit}
      ></MultiSlider>
    </div>
  )
}
