import './Table.scss'

import { cls } from '@react/utils/classname'
import { ReactNode, useState } from 'react'

import { SortTableHeader } from '../Components/Table/SortTableHeader'
import { TableCell } from '../Components/Table/TableCell'
import { ImageType, ItemType } from '../FilterableListTypes'

type TableHeadersType = {
  items: { sortable?: boolean; value: string; key?: string }[]
  sortable?: boolean
}

export type TableRowsType = {
  value?: string
  link?: string
  url?: string
  image?: ImageType
  customTemplate?: ItemType
}

type TableProps = {
  classNames?: string
  customTableDataTemplate?: (item: ItemType) => ReactNode
  onTableRowClick?: boolean
  showNumberOfRows?: number
  tableHeaders?: TableHeadersType
  tableRows?: TableRowsType[][]
  tableRowsTemplate?: ReactNode
  handleOnCLickHeaders?: (column: string, order: string) => void
}

export const Table = ({
  classNames = '',
  customTableDataTemplate,
  onTableRowClick = false,
  showNumberOfRows,
  tableHeaders = null,
  tableRows = null,
  tableRowsTemplate,
  handleOnCLickHeaders,
}: TableProps) => {
  const [activeSortHeader, setActiveSortHeader] = useState(null)

  const handleOnClick = (href: string) => (window.location.href = href)

  const handleOnSortHeader = (column: string, order: string) => {
    setActiveSortHeader({ column, order })
    handleOnCLickHeaders(column, order)
  }

  return (
    <table className={`cmp-filterable-list__table ${classNames}`}>
      {tableHeaders && (
        <thead className={'cmp-filterable-list__table-thead'}>
          <tr className={'cmp-filterable-list__table-row'}>
            {tableHeaders.items.map((header, index) => (
              <th key={index} className={'cmp-filterable-list__table-th'}>
                {header.value}
                {header.sortable && <SortTableHeader activeSortHeader={activeSortHeader} header={header} handleOnCLickHeaders={handleOnSortHeader} />}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody className={'cmp-filterable-list__table-tbody'}>
        {tableRowsTemplate}
        {!tableRowsTemplate &&
          tableRows?.map((tableRow, index) => (
            <tr
              className={cls({ 'cmp-filterable-list__table-row': true, 'cmp-filterable-list__table--hidden-row': index + 1 > showNumberOfRows })}
              key={index}
              onClick={() => onTableRowClick && handleOnClick(tableRow.find(item => item.url).url)}
            >
              {tableRow.map((rowData, rowIndex) => (
                <td key={rowIndex} className={'cmp-filterable-list__table-td'}>
                  <div className="cmp-filterable-list__table-td--table-cell">
                    {rowData.customTemplate && customTableDataTemplate(rowData.customTemplate)}
                    {!rowData.customTemplate && (
                      <>
                        {rowData.link && (
                          <a href={rowData.link} className="cmp-filterable-list__list-link">
                            <TableCell data={rowData} />
                          </a>
                        )}
                        {!rowData.link && <TableCell data={rowData} />}
                      </>
                    )}
                  </div>
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  )
}
