import React from 'react'
import ReactDOM from 'react-dom/client'

import { generateRandomId } from './generateRandomId'

const COMPONENT_ATTR = '[data-component]'
const PROPS_DATASET = 'props:'

export function register(root, fn) {
  const rootElement = typeof root === 'string' ? document.getElementById(root) : root

  const render = rootElement => {
    fn(component => {
      if (!rootElement.dataset.componentId) {
        rootElement.dataset.componentId = generateRandomId(16)
        ReactDOM.createRoot(rootElement).render(component)
      }
    }, rootElement)
  }

  if (rootElement) {
    // Allowing us to target DOM elements. Eg. <image-wrapper>
    const isInstanceOfHTMLCollection = Boolean(rootElement instanceof HTMLCollection)

    if (!isInstanceOfHTMLCollection && (rootElement.length === 1 || rootElement.length === undefined)) {
      return render(rootElement)
    }

    const collections = Array.from(rootElement)
    return collections.map(rel => render(rel))
  }
}

export function registerComponent(componentName, component) {
  const components = document.querySelectorAll(COMPONENT_ATTR)
  if (!components || components.length === 0) {
    return false
  }

  const rootElements = [...components].filter(component => component.dataset.component === componentName)

  const registerComponentWithProps = rootElement => {
    const dataset = Object.assign({}, rootElement.dataset)
    const datasetProps = Object.keys(dataset).filter(attr => attr.startsWith(PROPS_DATASET))

    let props = {}
    datasetProps.map(key => {
      const propName = key.split(':')[1] || null
      const propValue = dataset[key]
      props = {
        ...props,
        [propName]: propValue,
      }
    })

    register(rootElement, render => {
      render(React.createElement(component, props, null))
    })
  }

  if (rootElements.length === 1) {
    return registerComponentWithProps(rootElements[0])
  } else {
    return rootElements.map(registerComponentWithProps)
  }
}
