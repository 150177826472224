import './EventCarouselItem.scss'

import ImageWrapper from '@react/ImageWrapper'
import { useContext } from 'react'

import { CalendarIcon } from '../../../../FilterableList/UI/CalendarIcon'
import { dateLine } from '../../../../FilterableList/utils/events'
import { FilterableListContext } from '../../../FilterableListContext'
import { ItemType } from '../../../FilterableListTypes'
import { Card } from '../../../UI/Card'

type CarouselItemProps = {
  item: ItemType
}

export const EventCarouselItem = ({ item }: CarouselItemProps) => {
  const { translationsState } = useContext(FilterableListContext)
  item.dateString = item.unscheduledEvent ? translationsState.unscheduledEventsDateLabel : dateLine(item.startDate, item.endDate, item.onDemand)

  return (
    <Card showMetaDate={true} buttonLabel={translationsState.readMoreLabel} item={item}>
      <div className="cmp-filterable-list__card-image-wrapper">
        {item.image && (
          <ImageWrapper
            modelJson={JSON.stringify(item.image)}
            classes="cmp-filterable-list__card-image cmp-responsive-image cmp-responsive-image--lazy"
            imageSrc={item.image.placeholder}
          />
        )}
        <div className="cmp-filterable-list__card-image--decoration">
          <CalendarIcon item={item} unscheduledEventsUpcomingLabel={translationsState.unscheduledEventsUpcomingLabel} />
          <div className="cmp-filterable-list__card-event-location">
            {item.city && <span className="cmp-filterable-list__card-event-location--city">{item.city}</span>}
            <span className="cmp-filterable-list__card-event-location--country">{item.country}</span>
          </div>
        </div>
      </div>
    </Card>
  )
}
