import './BarChart.scss'

import { useEffect, useMemo, useRef } from 'react'

import { BarChartPropsType } from './BarChartTypes'

const BARCHART_COLORS = {
  active: 'hsl(351deg 100% 46.1%)', // --color-primary-500
  inactive: 'hsl(0deg 0% 45%)', // --color-gray-500
}

const getPixelRatio = context => {
  const backingStore =
    context.backingStorePixelRatio ||
    context.webkitBackingStorePixelRatio ||
    context.mozBackingStorePixelRatio ||
    context.msBackingStorePixelRatio ||
    context.oBackingStorePixelRatio ||
    context.backingStorePixelRatio ||
    1

  return (window.devicePixelRatio || 1) / backingStore
}

export function BarChart({ data, minMax }: BarChartPropsType) {
  const ref = useRef<HTMLCanvasElement>(null)

  const maxValue = useMemo(() => Math.max(...data.map(d => parseInt(d.value))), [data])

  useEffect(() => {
    const canvas = ref.current

    if (!canvas) {
      return
    }

    const ctx = canvas.getContext('2d')

    const ratio = getPixelRatio(ctx)
    const width = getComputedStyle(canvas).getPropertyValue('width')
    const height = getComputedStyle(canvas).getPropertyValue('height')

    ctx.scale(ratio, ratio)

    canvas.width = Math.ceil(Number(width.split('px')[0])) * ratio
    canvas.height = Math.ceil(Number(height.split('px')[0])) * ratio
    canvas.style.width = `${width}px`
    canvas.style.height = `${height}px`

    const padding = 5
    const actualHeight = canvas.height - padding * 4
    const actualWidth = canvas.width - padding * 2
    const barWidth = actualWidth / data.length - padding
    let cx = padding

    data.forEach(d => {
      const value = parseInt(d.value)
      const barHeight = (actualHeight * value) / maxValue

      ctx.fillStyle = parseInt(d.key) >= minMax.min && parseInt(d.key) <= minMax.max ? BARCHART_COLORS.active : BARCHART_COLORS.inactive

      ctx.fillRect(cx, canvas.height - barHeight - padding * 2, barWidth, barHeight)

      cx += barWidth + padding
    })
  })

  return <canvas className="cmp-barchart" ref={ref} />
}
