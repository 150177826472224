import './FilterableList.scss'

import { memo } from 'react'

import { EmptyComponent } from './Components/EmptyComponent'
import { ErrorComponent } from './Components/ErrorComponent'
import { FilterableListContext } from './FilterableListContext'
import { FilterableListSwitcher } from './FilterableListSwitcher'
import { FilterableListProps, TranslationsType } from './FilterableListTypes'

export const FilterableList = memo(function FilterableList({
  listStyleTemplate,
  isLoading,
  onLoadMore,
  responseData,
  translations,
  ...rest
}: FilterableListProps) {
  let translationsState: TranslationsType
  try {
    translationsState = JSON.parse(translations)
  } catch (e) {
    translationsState = null
  }

  if (typeof responseData === 'string' && responseData === 'error') {
    return <ErrorComponent message={translationsState.error} />
  }

  if (responseData.items.length < 1 && 'type' in rest && rest.type !== 'relatedJob') {
    return <EmptyComponent message={translationsState.noResults} />
  }

  return (
    <FilterableListContext.Provider value={{ isLoading, onLoadMore, responseData, propsState: { ...rest, listStyleTemplate }, translationsState }}>
      <FilterableListSwitcher />
    </FilterableListContext.Provider>
  )
})
