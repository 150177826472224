import './GalleryLightbox.scss'

import ResponsiveImg from '@react/ResponsiveImage/ResponsiveImage'
import { cls } from '@react/utils/classname'
import { isMobile } from '@site/js/utils/breakpoint'
import { useEffect, useState } from 'react'

import { GalleryLightboxProps, Image, LightBoxConfigImages, ModelObject } from './GalleryLightboxTypes'

const BODY_SELECTORS = {
  rootPageElement: document.querySelector('.root.container') as HTMLElement,
  bodyElement: document.querySelector('body') as HTMLBodyElement,
}

const getLightboxConfig = (model: ModelObject): LightBoxConfigImages => {
  const lightBoxImages = {
    imagesArray: [],
    imagesForMobile: [],
  }

  if (model?.imagesArray?.length && model?.imagesForMobile?.length) {
    lightBoxImages.imagesArray = [...model.imagesArray].map(images => modifyImageArray(images)).flat()
    lightBoxImages.imagesForMobile = modifyImageArray(model.imagesForMobile)
  }
  return lightBoxImages
}

const modifyImageArray = (imageArray: Image[]) =>
  imageArray.map(image => {
    return {
      pattern: convertPlaceHolderToLightboxSrc(image.imageUrlPattern),
      originalWidth: image.originalWidth,
      originalHeight: image.originalHeight,
      caption: image.caption,
      placeholder: image.placeholderImageMarkup,
      copyrightOwner: image.copyrightOwner,
      downloadLink: convertPlaceHolderToDownload(image.fallbackImageUrl),
      name: image.name,
    }
  })

const convertPlaceholder = (placeholder: string, desiredKeys: string[]): string => {
  const newPlaceholder = decodeURIComponent(placeholder).replace(/(:[0-9-]*)\?/g, '?')
  let newParamString = ''
  let root = ''
  if (newPlaceholder.indexOf('?') > -1) {
    root = newPlaceholder.slice(0, newPlaceholder.indexOf('?'))
    const params = new URLSearchParams(newPlaceholder.slice(newPlaceholder.indexOf('?')))
    const newParams = []
    desiredKeys.forEach(key => {
      if (params.has(key)) {
        newParams.push(`${key}=${params.get(key)}`)
      }
    })
    newParamString = `?${newParams.join('&')}`
  } else {
    root = newPlaceholder
  }

  return `${root}${newParamString}`
}

const convertPlaceHolderToDownload = (placeholder: string): string => {
  return convertPlaceholder(placeholder, ['fmt'])
}

const convertPlaceHolderToLightboxSrc = (placeholder: string): string => {
  return convertPlaceholder(placeholder, ['fmt', 'hei'])
}

export default function GalleryLightbox({ model, componentId }: GalleryLightboxProps) {
  const [lightboxVisible, setLightboxVisible] = useState(false)
  const [modelObject, setModelObject] = useState(null)
  const [activeSlide, setActiveSlide] = useState(0)

  const lightBoxConfig = isMobile ? getLightboxConfig(modelObject).imagesForMobile : getLightboxConfig(modelObject).imagesArray

  useEffect(() => {
    try {
      setModelObject(JSON.parse(model))
    } catch (err) {
      setModelObject(null)
    }
  }, [model])

  useEffect(() => {
    const openLightboxHandler = (e: CustomEvent) => {
      setLightboxVisible(e.detail.id === componentId)
      setActiveSlide(Number(e.detail.imageIndex) || 0)
      BODY_SELECTORS.bodyElement.style.overflowY = 'hidden'
      BODY_SELECTORS.rootPageElement.style.overflowY = 'hidden'
    }

    window.addEventListener('cmp-media-gallery-image-click', openLightboxHandler)

    return () => window.removeEventListener('cmp-media-gallery-image-click', openLightboxHandler)
  }, [])

  const showSlide = (slideIndex: number) => {
    let goToSlide = activeSlide + slideIndex

    if (goToSlide < 0) {
      goToSlide = lightBoxConfig.length - 1
    }

    if (goToSlide > lightBoxConfig.length - 1) {
      goToSlide = 0
    }

    setActiveSlide(goToSlide)
  }

  const closeLightbox = () => {
    setLightboxVisible(false)
    BODY_SELECTORS.bodyElement.style.overflowY = ''
    BODY_SELECTORS.rootPageElement.style.overflowY = ''
  }

  return (
    lightboxVisible &&
    modelObject && (
      <div className="cmp-media-gallery__lightbox" id={`lightbox-${componentId}`}>
        <div className="cmp-gallery-lightbox__wrapper">
          <div className="cmp-gallery-lightbox">
            {lightBoxConfig.map((item, index) => (
              <div
                className={cls({
                  'cmp-gallery-lightbox__slides': true,
                  'cmp-gallery-lightbox__slides--active': activeSlide === index,
                })}
                key={index}
              >
                <div className="cmp-gallery-lightbox__image-wrapper">
                  <ResponsiveImg config={item} additionalClasses="cmp-gallery-lightbox__image" />
                  {item.copyrightOwner && <p className="cmp-gallery-lightbox__copyright">Photo: {item.copyrightOwner}</p>}
                </div>
                <div className="cmp-gallery-lightbox__image--info">
                  <p className="cmp-gallery-lightbox__caption">{item.caption}</p>
                  <div className="cmp-gallery-lightbox__link-counter">
                    {modelObject.enableDownload && (
                      <a
                        className="cmp-gallery-lightbox__download-link"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={item.downloadLink}
                        download={item.name}
                      >
                        <span className="icon-download icon--sm"></span>
                        Download High-res
                      </a>
                    )}
                    <span className="cmp-gallery-lightbox__separator">|</span>
                    <p className="cmp-gallery-lightbox__current">
                      {index + 1} / {lightBoxConfig.length}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <div className="cmp-gallery-lightbox__navigation">
              <div className="cmp-gallery-lightbox__arrow--is-previous" onClick={() => showSlide(-1)}>
                <span className="icon-keyboard_arrow_left"></span>
              </div>
              <div className="cmp-gallery-lightbox__arrow--is-next" onClick={() => showSlide(+1)}>
                <span className="icon-keyboard_arrow_right"></span>
              </div>
            </div>
            <div className="cmp-gallery-lightbox__close-btn" onClick={closeLightbox}>
              <span className="icon-close icon--md"></span>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
