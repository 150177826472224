import { useContext } from 'react'

import { ButtonsWrapper } from '../Components/ButtonsWrapper'
import { Teaser } from '../Components/Teaser'
import { FilterableListContext } from '../FilterableListContext'
import { modifyVideoItem } from '../utils/videolist'

export const Videos = () => {
  const { responseData, propsState, translationsState } = useContext(FilterableListContext)
  const { items } = responseData
  const isVideoTeaserList = () => propsState.videoteaserlist === 'true'

  const CustomTitle = ({ title }: { title: string }) =>
    isVideoTeaserList() ? (
      <div className="cmp-filterable-list__card-title-wrapper">
        <span className="icon-play_circle_outline icon--md"></span>
        <h3 className="cmp-filterable-list__card-title">{title}</h3>
      </div>
    ) : (
      <h3 className="cmp-filterable-list__card-title">{title}</h3>
    )

  return (
    <div className="cmp-filterable-list">
      <div className={`cmp-filterable-list__cards cmp-filterable-list__video-type cmp-filterable-list--${isVideoTeaserList() ? 3 : 4}-columns`}>
        {items?.map((item, index) => (
          <Teaser
            item={modifyVideoItem({
              item,
              viewLabel: translationsState.viewLabel,
              viewsLabel: translationsState.viewsLabel,
              temporal: translationsState.temporal,
            })}
            key={`${index}-${item.title}`}
            customTitle={<CustomTitle title={item.title} />}
            showButton={false}
            showDescription={false}
            showTag={false}
            showMetaTag={item.viewCounter > 0}
            showMetaTimeToRead={false}
          />
        ))}
      </div>
      <ButtonsWrapper url={propsState.powertvPageLink} linkText={'Go to Energy TV'} />
    </div>
  )
}
