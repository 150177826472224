/**
 * Composes functions
 * @param  {...any} fns
 */
export const compose =
  (...fns) =>
  x =>
    fns.reduceRight((y, f) => f(y), x)

/**
 * empty function
 */
export const noop = () => {}

/**
 * checks for null and undefined
 * @param {any} x
 */
export const exists = x => x != null

/**
 * pust value in array (not null or undefined)
 * @param {any} x
 */
export const arrayOf = x => [x].filter(exists)

/**
 * queries selector from an element
 * @param {*} s
 * @param {*} el
 */
export const selectAll = (s, el = document) => (el.querySelectorAll ? Array.from(el.querySelectorAll(s)) : noop())

/**
 * turns an object of selectors
 * into an object of references to the DOM elements
 * @param {Object} selectors
 */
export const getRefs =
  (selectors = {}) =>
  el => {
    const refs = {}
    Object.keys(selectors).forEach(key => {
      refs[key] = selectAll(selectors[key], el)
    })
    return refs
  }

/**
 * Filter to get only distinct values
 * @param {any} value
 * @param {number} index
 * @param {array} arr
 * use: myArray.filter(distinct)
 */
export const distinct = (value, index, arr) => arr.indexOf(value) === index

/**
 * calls and catches function call errors
 * @param {function} fn function to try
 */
export const tryCatch = fn => {
  try {
    return fn()
  } catch (error) {
    console.error(error)
  }
}

/**
 * parse data to json
 * @param {any} data
 */
export const parseJson = data => tryCatch(() => JSON.parse(data))
/**
 * finds closets element
 * @param {*} el
 */
export const closest = str => el => el.closest ? el.closest(str) : null

/**
 * adds event listernes
 * @param {string} type
 * @param {function} fn
 */
export const addEvent = (type, fn) => el => el.addEventListener ? el.addEventListener(type, fn) : noop()

/**
 * creates an immutable clone of an object and encode its data
 * @param  {*} obj the object to clone
 */
export const clone = obj => {
  // Get the object type
  const type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()

  // If an object, loop through and recursively encode
  if (type === 'object') {
    const cloned = {}
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        cloned[key] = clone(obj[key])
      }
    }
    return cloned
  }

  // If an array, create a new array and recursively encode
  if (type === 'array') {
    return obj.map(function (item) {
      return clone(item)
    })
  }

  // If the data is a string, encode it
  if (type === 'string') {
    const temp = document.createElement('div')
    temp.textContent = obj
    return temp.innerHTML
  }

  // Otherwise, return object as is
  return obj
}

/**
 * sets references to elements
 * @param {HTMLElement} el
 */
export const setRefs = (el = {}) => {
  if (!el.querySelectorAll) {
    return
  }
  const refs = {}
  const refElements = el.querySelectorAll('[ref]')
  Array.from(refElements).forEach(ref => {
    const name = ref.getAttribute('ref')
    if (!name) {
      return
    }
    if (Array.isArray(refs[name])) {
      refs[name].push(ref)
    } else {
      refs[name] = [ref]
    }
  })
  el.refs = refs
  return refs
}

export const replaceLabelPlaceholders = (label, ...args) => {
  return label.replace(/(\{[0-9]+\})/g, () => args.shift())
}

/**
 * Creates an HTML element from string
 * Expects htmlString to contain single parent element
 */
export const createElementFromString = htmlString => {
  const temp = document.createElement('div')
  temp.innerHTML = htmlString.trim()

  return temp.firstChild
}

/**
 * @see https://stackoverflow.com/a/52854585
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/@media/any-hover
 */
export const isTouchOnly = window.matchMedia('(any-hover: none)').matches

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
