import './MultiSlider.scss'

import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'

import { MultiSliderTypes } from './MultiSliderTypes'

export function MultiSlider({ keyToStepMapping, min, max, onChange, minMax, unit }: MultiSliderTypes) {
  const slider = useRef(null)
  const rangeMinInput = useRef(null)
  const rangeMaxInput = useRef(null)
  const numberMinInput = useRef(null)
  const numberMaxInput = useRef(null)
  const keyToStepMappingLength = useMemo(() => keyToStepMapping.length - 1, [keyToStepMapping])
  const [latestValidMinMaxIndex, setLatestValidMinMaxIndex] = useState({ min: keyToStepMapping.indexOf(min), max: keyToStepMapping.indexOf(max) })

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleInputNumberOnChange = (event: ChangeEvent<HTMLInputElement>, type: 'min' | 'max') => {
    const isTypeMin = type === 'min'
    if (event.target.value === '') {
      event.target.value = isTypeMin ? keyToStepMapping[0].toString() : keyToStepMapping[keyToStepMappingLength].toString()
    }

    if (keyToStepMapping.indexOf(parseInt(event.target.value)) >= 0) {
      setLatestValidMinMaxIndex({
        min: isTypeMin ? keyToStepMapping.indexOf(parseInt(event.target.value)) : parseInt(rangeMinInput.current.value),
        max: isTypeMin ? parseInt(rangeMaxInput.current.value) : keyToStepMapping.indexOf(parseInt(event.target.value)),
      })
    } else {
      setLatestValidMinMaxIndex({
        min: isTypeMin
          ? keyToStepMapping.indexOf(
              keyToStepMapping[minMax.min <= numberMinInput.current.value ? ++rangeMinInput.current.value : --rangeMinInput.current.value],
            )
          : parseInt(rangeMinInput.current.value),
        max: isTypeMin
          ? parseInt(rangeMaxInput.current.value)
          : keyToStepMapping.indexOf(
              keyToStepMapping[minMax.max >= numberMaxInput.current.value ? --rangeMaxInput.current.value : ++rangeMaxInput.current.value],
            ),
      })
    }

    onChange({
      min: isTypeMin ? parseInt(event.target.value) : keyToStepMapping[rangeMinInput.current.value],
      max: isTypeMin ? keyToStepMapping[rangeMaxInput.current.value] : parseInt(event.target.value),
    })
  }

  useEffect(() => {
    if (!slider.current || !rangeMinInput.current || !rangeMaxInput.current || !numberMinInput.current || !numberMaxInput.current) {
      return
    }

    let slideMin = parseInt(rangeMinInput.current.value)
    let slideMax = parseInt(rangeMaxInput.current.value)

    if (slideMin > slideMax) {
      ;[slideMin, slideMax] = [slideMax, slideMin]
    }

    numberMinInput.current.value = keyToStepMapping[slideMin]
    numberMaxInput.current.value = keyToStepMapping[slideMax]

    const numberMinValue = parseInt(numberMinInput.current.value)
    const numberMaxValue = parseInt(numberMaxInput.current.value)

    if (numberMinValue > numberMaxValue) {
      const tmp = numberMinValue
      numberMinInput.current.value = numberMaxValue
      numberMaxInput.current.value = tmp
    }

    rangeMinInput.current.value = keyToStepMapping.indexOf(numberMinValue)
    rangeMaxInput.current.value = keyToStepMapping.indexOf(numberMaxValue)
  })

  return (
    <div ref={slider} className="cmp-multi-slider">
      <div className="cmp-multi-slider__range">
        <input
          className="cmp-multi-slider__range-minimum"
          value={keyToStepMapping.indexOf(minMax.min) >= 0 ? keyToStepMapping.indexOf(minMax.min) : latestValidMinMaxIndex.min}
          min={0}
          max={keyToStepMappingLength}
          type="range"
          ref={rangeMinInput}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ min: keyToStepMapping[parseInt(event.target.value)], max: keyToStepMapping[rangeMaxInput.current.value] })
          }
        />
        <input
          className="cmp-multi-slider__range-maximum"
          value={keyToStepMapping.indexOf(minMax.max) >= 0 ? keyToStepMapping.indexOf(minMax.max) : latestValidMinMaxIndex.max}
          min={0}
          max={keyToStepMappingLength}
          type="range"
          ref={rangeMaxInput}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ min: keyToStepMapping[rangeMinInput.current.value], max: keyToStepMapping[parseInt(event.target.value)] })
          }
        />
      </div>
      <div className="cmp-multi-slider__number">
        <div className="cmp-multi-slider__number-minimum">
          <input
            type="number"
            value={minMax.min}
            min={min}
            max={max}
            ref={numberMinInput}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputNumberOnChange(event, 'min')}
            required
          />
          {unit && <span className="cmp-multi-slider__number-unit">{unit}</span>}
        </div>
        <div className="cmp-multi-slider__number-maximum">
          <input
            type="number"
            value={minMax.max}
            min={min}
            max={max}
            ref={numberMaxInput}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputNumberOnChange(event, 'max')}
            required
          />
          {unit && <span className="cmp-multi-slider__number-unit">{unit}</span>}
        </div>
      </div>
    </div>
  )
}
