import { useContext } from 'react'

import { FilterableListContext } from '../../../FilterableListContext'
import { ItemType } from '../../../FilterableListTypes'
import { CalendarIcon } from '../../../UI/CalendarIcon'
import { ListTypeItem } from '../../../UI/ListTypeItem'
import { dateLine } from '../../../utils/events'

type EventListItemProps = {
  item: ItemType
}

export const EventListItem = ({ item }: EventListItemProps) => {
  const { translationsState } = useContext(FilterableListContext)
  item.dateString = item.unscheduledEvent ? translationsState.unscheduledEventsDateLabel : dateLine(item.startDate, item.endDate, item.onDemand)
  item.timeToRead = item.location

  return (
    <>
      <ListTypeItem
        item={item}
        showDescription={true}
        showMetaTag={false}
        showMetaDate={!item.onDemand}
        showButton={false}
        showTag={Boolean(item.tagValue)}
      >
        <CalendarIcon item={item} unscheduledEventsUpcomingLabel={translationsState.unscheduledEventsUpcomingLabel} />
      </ListTypeItem>
    </>
  )
}
