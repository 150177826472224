export function getRangeSliderSearchParameters(searchKeyParam: string): Record<string, string> {
  if (!searchKeyParam) {
    return {}
  }

  let foundParams: Record<string, string> = {}
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  for (const [key, value] of urlParams) {
    if (key.includes(searchKeyParam)) {
      foundParams = { ...foundParams, [key.split('.')[1]]: value }
    }
  }

  return foundParams
}
