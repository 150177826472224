import './TagList.scss'

import { trackEvent } from '@site/js/utils/tracking'

import { ABBREVIATED, CLICKABLE, NON_CLICKABLE, REMOVABLE, TEAMS_NOTIFICATIONS } from './constants'
import { TagItem } from './TagItem'

type TeamsNotificationData = {
  channelUrl: string
  title: string
  webhookUrl: string
}

type RemovableData = {
  key: string
  value: string
  property: string
}

type TagListProps = {
  type: string
  data: string | TeamsNotificationData[] | RemovableData[] | string[]
  handleTracking?: (param) => void
  endpointurl?: string
  listid?: string
}

function handleClickableTagItemTracking(title: string) {
  trackEvent({
    event: 'interaction',
    eventSource: 'aem',
    action: 'click',
    componentName: 'tag-list',
    interactionType: 'link',
    title,
  })
}

function renderTagsByType({ data, type, handleTracking, endpointurl, listid }: TagListProps) {
  let tagsTemplate = null
  switch (type) {
    case TEAMS_NOTIFICATIONS:
      tagsTemplate = (data as TeamsNotificationData[]).map(({ title, channelUrl }) => (
          <TagItem key={channelUrl} title={title} href={channelUrl} handleTracking={handleTracking} type={type} />
      ))
      break
    case NON_CLICKABLE:
      tagsTemplate = (data as string).split(',').map(title => <TagItem key={title} title={title} type={type} />)
      break
    case CLICKABLE:
      tagsTemplate = JSON.parse(data as string).map(item => (
          <TagItem
              key={item.tagId}
              title={item.tagTitle}
              href={item.tagId}
              endpointUrl={endpointurl}
              type={type}
              handleTracking={handleClickableTagItemTracking}
              filterKey={item.filterKey}
              listId={listid}
          />
      ))
      break
    case REMOVABLE:
      tagsTemplate = (data as RemovableData[]).map(({ key, value, property }) => <TagItem key={key} title={value} property={property} type={type} />)
      break
    case ABBREVIATED:
      tagsTemplate = (data as string[]).map(title => <TagItem key={title} title={title} type={type} />)
      break
    default:
      tagsTemplate = null
  }
  return tagsTemplate
}

export function TagList({ data, type, handleTracking, endpointurl, listid }: TagListProps) {
  if (data === null) {
    return null
  }

  return <ul className={`cmp-taglist cmp-taglist--is-${type}`}>{renderTagsByType({ data, type, handleTracking, endpointurl, listid })}</ul>
}